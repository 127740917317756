import { action, Action, createStore, persist } from "easy-peasy";
import { User, Payload } from "../type";
import ls from "localstorage-slim";

export interface EasyPeasyStore {
  isAuthenticated: boolean;
  user: User;
  addUser: Action<this, Payload>;
  logout: Action<this>;
  token: string;
}

const initialState = {
  token: "",
  isAuthenticated: false,
  user: {
    email: "",
    name: "",
    org_id: "",
    user_id: "",
    access_level: -1,
  },
};

export const store = createStore<EasyPeasyStore>(
  persist(
    {
      ...initialState,
      addUser: action((state: any, payload: Payload) => {
        state.user = payload.user;
        state.token = payload.token;
        state.isAuthenticated = true;
      }),

      logout: action((state: any) => {
        state.user = {
          email: "",
          name: "",
          org_id: "",
          access_level: -1,
        };
        state.token = "";
        state.isAuthenticated = false;
      }),
    },
    {
      storage: {
        getItem: async function (key) {
          const value: any = ls.get(key, { decrypt: true });
          return value;
        },

        setItem: function (key, value) {
          ls.set(key, value);
        },

        removeItem: function (key) {
          ls.remove(key);
        },
      },
      allow: ["user", "isAuthenticated", "token"],
    }
  ),
  {
    name: "zoundify",
  }
);
