import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import axios from "axios";
import { format, isValid, parse, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

export const CalenarList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: calenders = [] } = useQuery(
    [queries.calendars, input.tab, user.org_id],
    () =>
      getRequest(
        `/calendars/list?org_id=${user.org_id}&status=${
          input.tab === 2 ? 0 : input.tab
        }`
      ),
    {
      enabled: !!input.tab || !!user.org_id,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Name</span>,
        accessor: "title",
      },
      {
        Header: () => <span className="text-white">Location</span>,
        accessor: "location.name",
      },
      {
        Header: () => <span className="text-white">Working</span>,
        accessor: "start_hours",
        //@ts-ignore
        Cell: ({ row }) => {
          const start = row.original.start_hours.slice(0, -1);
          const end = row.original.closing_hours.slice(0, -1);
          return `${start} - ${end}`;
        },
      },
      {
        Header: () => <span className="text-white">Duration</span>,
        accessor: "duration",
      },
      {
        Header: () => <span className="text-white">Max Slots</span>,
        accessor: "max_slots",
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/calendars/update/${data.id}`, {
      state: {
        name: data.agent_name,
      },
    });
  };

  return (
    <div className="w-full h-full p-10 text-perl">
      <List
        columns={columns}
        data={calenders}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black">Calendar</h1>
          <div className="flex   text-disabled  justify-between items-center">
            <ToggleGroup
              innerClass="w-full text-disabled flex space-x-6"
              outerClass="pb-1 text-sm  data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input["tab"]}
              onValueChange={(value: number) =>
                setInput({ ...input, ["tab"]: value })
              }
            />

            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/calendars/create")}
                className="bg-green text-white px-3 py-1 rounded-full"
              >
                Create
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
