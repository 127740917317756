import { useState } from "react";
import { Outlet } from "react-router-dom";
import { MainHeader, Sidebar } from "../components";

export const MainLayout = () => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  const toggleSideMenu = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  };

  return (
    <div className="w-screen h-screen">
      <MainHeader onToggle={toggleSideMenu} />
      <div className="flex h-[calc(100vh-64px)]">
        <Sidebar isOpen={isSideMenuOpen} onClose={closeSideMenu} />
        <div className={`w-full  bg-secondary`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
