import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

interface Plan {
  id: string;
  name: string;
  price: string;
  units: string;
}

interface Employee {
  id: string;
  name: string;
  description: string;
  tasks: string[];
  image_path: string;
}

type PlanResponse = Plan[];
type APIResponse = Employee[];

export const VirtualStaff = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const [selectedPlans, setSelectedPlans] = useState<{ [key: string]: string }>(
    {}
  );
  const [plansData, setPlansData] = useState<{ [key: string]: Plan[] }>({});

  const { data, isLoading, isError } = useQuery<APIResponse>(
    [queries.agentTemplateList],
    () => getRequest(`/v2/agent_templates/list?org_id=${user.org_id}`),
    {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  );

  const agentTemplates = data || [];

  useEffect(() => {
    const fetchPlans = async (templateId: string) => {
      const response = await getRequest(
        `/plans/template?template_id=${templateId}`
      );
      return response as PlanResponse;
    };

    if (agentTemplates.length === 0) return;

    const initialSelectedPlans: { [key: string]: string } = {};

    const plansPromises = agentTemplates.map(async (template) => {
      const plans = await fetchPlans(template.id);

      setPlansData((prevData) => ({
        ...prevData,
        [template.id]: plans || [],
      }));

      if (plans.length > 0) {
        initialSelectedPlans[template.id] = plans[0].name;
      }
    });

    Promise.all(plansPromises).then(() => {
      setSelectedPlans(initialSelectedPlans);
    });
  }, [agentTemplates]);

  if (isLoading)
    return <div className="text-center text-white">Loading...</div>;
  if (isError)
    return <div className="text-center text-white">Error loading data</div>;

  return (
    <div className="w-full h-full p-4 text-white">
      <ScrollArea className="h-[calc(100vh-85px)]">
        <div className="text-center w-full px-4">
          <h1 className="text-white text-2xl pt-20 mb-20">
            Who would you like to
            <span className="text-4xl p-2 font-black text-logo font-custom letter-spacing: ">
              hire
            </span>
            today?
          </h1>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 pt-18">
            {agentTemplates.map((template: Employee) => (
              <div
                key={template.id}
                className="bg-primary border border-header-border p-6 rounded-lg text-white hover:bg-[#333333] cursor-pointer flex flex-col h-[450px] relative"
              >
                <div className="flex items-center mb-4">
                  {template.image_path ? (
                    <img
                      src={template.image_path}
                      alt={`${template.name}`}
                      className="h-10 w-10 flex items-center justify-center rounded-lg mr-4"
                    />
                  ) : (
                    <div className="h-10 w-10 flex items-center justify-center bg-header-border rounded-lg mr-4">
                      <span className="text-2xl font-bold">+</span>
                    </div>
                  )}

                  <h2 className="text-xl font-bold">{template.name}</h2>
                </div>

                <p className="text-left text-perl mb-4">
                  {template.description}
                </p>

                <ul className="list-none text-left mb-4">
                  {template.tasks.map((task, index) => (
                    <li className="flex items-center mb-2" key={index}>
                      <svg
                        className="h-4 w-4 mr-2 text-green-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      <span>{task}</span>
                    </li>
                  ))}
                </ul>

                <div className="mt-auto">
                  <div className="flex items-center justify-between mb-4">
                    <div className="relative flex-grow">
                      <select
                        className="w-full bg-primary border border-header-border text-white px-3 py-2 pr-8 rounded-lg text-sm appearance-none"
                        value={selectedPlans[template.id] || ""}
                        onChange={(e) =>
                          setSelectedPlans({
                            ...selectedPlans,
                            [template.id]: e.target.value,
                          })
                        }
                      >
                        {plansData[template.id]?.map((plan) => (
                          <option key={plan.name} value={plan.name}>
                            {plan.name}
                          </option>
                        ))}
                      </select>

                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    <div
                      className="ml-4 text-right flex-shrink-0 w-full"
                      style={{
                        maxWidth: "150px",
                        wordWrap: "break-word",
                      }}
                    >
                      {selectedPlans[template.id] &&
                        plansData[template.id] &&
                        plansData[template.id].length > 0 &&
                        plansData[template.id]
                          .filter(
                            (plan) => plan.name === selectedPlans[template.id]
                          )
                          .map((plan) => (
                            <div className="text-lg" key={plan.name}>
                              <span className="font-black text-yellow-400">
                                ${plan.price}
                              </span>
                              <span className="font-normal text-white">/</span>
                              <span className="font-normal text-white">
                                month {plan.units} Calls
                              </span>
                            </div>
                          ))}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      const selectedPlan = plansData[template.id]?.find(
                        (plan) => plan.name === selectedPlans[template.id]
                      );
                      if (selectedPlan) {
                        navigate(
                          `/agents/create/${template.id}/${selectedPlan.id}`
                        );
                      }
                    }}
                    className="bg-[#00A86B] text-white px-6 py-2 rounded-full text-sm font-semibold hover:bg-[#009960] transition-colors duration-200 w-full"
                    disabled={!selectedPlans[template.id]}
                  >
                    Hire Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
