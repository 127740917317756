import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

export const UpdateLocation = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const user = useStoreState((store) => store.user);

  const { data: location = {} } = useQuery(
    [queries.locations, id],
    () => getRequest(`/locations/?location_id=${id}`),
    {
      enabled: !!id,
    }
  );
  const [formData, setFormData] = useState<any>({
    name: "",
    phone: "",
    description: "",
    address: "",
    status: true,
  });

  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    description: "",
    address: "",
    status: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateLocation } = useMutation(
    putRequest,
    optimisticOptions(queries.locations)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { name, phone, description, address, status } = formData;
    updateLocation({
      endPoint: `/locations/?location_id=${id}`,
      payload: {
        name,
        phone,
        description,
        address,
        status: status === true ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (location) {
      const { name, phone, description, address, status } = location;
      setFormData({
        ...formData,
        name,
        phone,
        description,
        address,
        status: status === 1 ? true : false,
      });
    }
  }, [location]);

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border  border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-white">Update</span>
          </div>

          <div className="flex space-x-6 text-white">
            {user.access_level > 0 && (<NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />)}

              {user.access_level > 0 &&(<button className="inline-flex space-x-2 items-center" >
                  <TrashIcon />
                </button>)}
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)] ">
              <div className="space-y-2 grid grid-cols-2 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="eg. Service station - San Jose"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  error={errors.name}
                >
                  <label htmlFor="name">Location Name</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Enter Contact Number"
                  value={formData.phone}
                  onChange={(e) => onChangeHandler(e.target.value, "phone")}
                  required
                  theme="white"
                  error={errors.phone}
                >
                  <label htmlFor="Phone">Contact Number</label>
                </TextInput>

                <div className="col-span-2">
                  <TextInput
                    type="text"
                    placeholder="eg. 91, Tring Lane, San Fransisco, CA 94100"
                    value={formData.address}
                    onChange={(e) => onChangeHandler(e.target.value, "address")}
                    required
                    theme="white"
                    error={errors.address}
                  >
                    <label htmlFor="Phone">Address</label>
                  </TextInput>
                </div>

                <div className="col-span-2">
                  <TextArea
                    className="focus:outline-none min-h-[25vh] bg-transparent border border-header-border rounded-lg p-2"
                    placeholder="Enter Location note (for internal use only)"
                    value={formData.description}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "description")
                    }
                    required
                    theme="white"
                    error={errors.description}
                  >
                    <label htmlFor="Phone" className="pt-4">
                      Note
                    </label>
                  </TextArea>
                </div>
              </div>
            </ScrollArea>
            <div className="flex justify-end">
            {user.access_level > 0 ?(<button type="submit" className="px-4 py-1.5 bg-green rounded-lg"  >
                Save
              </button>): null}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
