import React from "react";
import * as Select_ from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";
import Person from "../../assets/images/person.png";

export const SpeakerSelect = ({
  data,
  onValueChange,
  defaultValue,
  children,
  ...props
}: any) => {
  return (
    <div className={props.className}>
      {children}

      <Select_.Root
        defaultValue={defaultValue === undefined ? "" : defaultValue}
        onValueChange={onValueChange}
      >
        <Select_.Trigger
          className={`w-full inline-flex h-8  items-center justify-between bg-transparent  focus:outline-none ${
            props.theme === "black" 
              ? defaultValue === undefined && "border px-4 text-black  border-header-border  data-[placeholder]:text-black"
              : defaultValue === undefined && "border px-4 text-white  border-header-border rounded-lg data-[placeholder]:text-white"
          }`}
          aria-label={props.placeholder}
        >
          <Select_.Value placeholder={props.placeholder} />
          <Select_.Icon>
            <TriangleDownIcon />
          </Select_.Icon>
        </Select_.Trigger>
        <Select_.Portal>
          <Select_.Content className="w-full overflow-hidden bg-primary rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
            <Select_.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white  cursor-default">
              <TriangleUpIcon />
            </Select_.ScrollUpButton>
            <Select_.Viewport className="space-y-6 py-4 text-white w-full border-b border-header-border">
              {data?.map((object: any) => renderContent(object))}
            </Select_.Viewport>
            <Select_.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-white cursor-default">
              <TriangleDownIcon />
            </Select_.ScrollDownButton>
          </Select_.Content>
        </Select_.Portal>
      </Select_.Root>
    </div>
  );
};

const renderContent = (obj: any) => {
  return (
    <Select_.Group key={obj.id}>
      <SelectItem value={obj.speaker_voice_id}>
        <div className="w-[100px] flex items-center space-x-4 ">
          <img
            src={Person}
            className="w-10 h-10 p-1 border border-header-border rounded-full"
          />
          <p>{obj.name}</p>
        </div>
      </SelectItem>
    </Select_.Group>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }: any, forwardedRef: any) => {
    return (
      <Select_.Item
        className={classnames(
          "text-xs leading-none rounded-[3px] flex items-center h-[25px] pr-[35px] px-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select_.ItemText>{children}</Select_.ItemText>
        <Select_.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select_.ItemIndicator>
      </Select_.Item>
    );
  }
);
