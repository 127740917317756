import React, { useEffect } from "react";
import Forward from "../../assets/images/forward.svg";
import Backward from "../../assets/images/backward.svg";
import Play from "../../assets/images/play.svg";
import Pause from "../../assets/images/pause.svg";
import { formatTime } from "../../utils";

interface CustomVideoPlayerProps {
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  played: number;
  setPlayed: (played: number) => void;
  setSeeking: (seeking: boolean) => void;
  loading: boolean;
  playerRef: React.MutableRefObject<any>;
  durationDisplay: string | undefined;
  currentTimeDisplay: string;
  setCurrentTimeDisplay: (currentTime: string) => void;
}

export const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  playing,
  setPlaying,
  played,
  setPlayed,
  setSeeking,
  loading,
  playerRef,
  durationDisplay,
  currentTimeDisplay,
  setCurrentTimeDisplay,
}) => {
  useEffect(() => {
    if (playerRef.current && !loading) {
      const currentTime = playerRef.current.getCurrentTime();
      setCurrentTimeDisplay(formatTime(currentTime));
    }
  }, [played, loading, playerRef]);

  const handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
    setSeeking(false);
    if (playerRef.current) {
      const seekTime = parseFloat(e.currentTarget.value);
      if (seekTime < 1) {
        playerRef.current.seekTo(seekTime, "microseconds");
      } else {
        playerRef.current.seekTo(seekTime, "seconds");
      }

      if (playing && playerRef.current.play) {
        playerRef.current.play();
      }
    }
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleForward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime + 10, "seconds");
    }
  };

  const handleBackward = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(currentTime - 10, "seconds");
    }
  };

  return (
    <div className="relative flex justify-between items-center text-white bg-primary rounded-full border border-gray-600 shadow-lg px-4">
      <div className="flex items-center justify-center space-x-4">
        <button disabled={loading} onClick={handleBackward}>
          <img src={Backward} width={30} height={30} alt="backward" />
        </button>
        <button disabled={loading} onClick={handlePlayPause}>
          {playing ? (
            <img src={Pause} width={30} height={30} alt="play" />
          ) : (
            <img src={Play} width={30} height={30} alt="pause" />
          )}
        </button>
        <button disabled={loading} onClick={handleForward}>
          <img src={Forward} width={30} height={30} alt="forward" />
        </button>
      </div>

      <div className="w-full p-3 flex items-center">
        <input
          type="range"
          min={0}
          max={1}
          step="any"
          value={played}
          onChange={handleSeekChange}
          onMouseDown={handleSeekMouseDown}
          onMouseUp={handleSeekMouseUp}
          className="w-full bg-secondary accent-white h-4 rounded-full"
        />
      </div>

      <div className="text-nowrap flex items-center justify-end">
        <span className="min-w-[70px]">{currentTimeDisplay}</span> /{" "}
        <span className="min-w-[70px] text-right">{durationDisplay}</span>
      </div>
    </div>
  );
};
