import React, { useState } from "react";
import * as Dialog_ from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { CustomSelect } from "../select";
import { queries, getRequest } from "../../react-query";
import { useQuery } from "react-query";

const languages = [
  {
    id: "1",
    name: "English - US",
    value: "en-US",
  },
  // {
  //   id: "2",
  //   name: "English - GB",
  //   value: "en-GB",
  // },
  // {
  //   id: "3",
  //   name: "English - NZ",
  //   value: "en-NZ",
  // },
  // {
  //   id: "4",
  //   name: "English - IN",
  //   value: "en-IN",
  // },
  // {
  //   id: "5",
  //   name: "Hindi",
  //   value: "hi",
  // },
  // {
  //   id: "6",
  //   name: "German",
  //   value: "de",
  // },
  // {
  //   id: "7",
  //   name: "Spanish",
  //   value: "es",
  // },
  // {
  //   id: "8",
  //   name: "Spanish - Latin",
  //   value: "es-LATAM",
  // },
];

export const NormalDialog = ({ onDubbingSave, children }: any) => {
  const [formData, setFormData] = useState({
    target_language_id: "",
  });

  // const { data: languages } = useQuery([queries.languages], () =>
  //   getRequest(`/languages`)
  // );

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Dialog_.Root>
      <Dialog_.Trigger asChild>{children}</Dialog_.Trigger>
      <Dialog_.Portal>
        <Dialog_.Overlay className="bg-primary opacity-80 data-[state=open]:animate-overlayShow fixed inset-0" />
        <Dialog_.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <Dialog_.Title className="text-mauve12 m-0 text-[17px] font-medium">
            Add Language
          </Dialog_.Title>

          <CustomSelect
            theme="black"
            className="w-full space-y-1"
            data={languages}
            onValueChange={(value: string) =>
              onChangeHandler(value, "target_language_id")
            }
            defaultValue={formData.target_language_id}
            placeholder="Select"
          ></CustomSelect>

          <div className="mt-[25px] flex justify-end">
            <Dialog_.Close asChild>
              <button
                onClick={()=>onDubbingSave(formData)}
                className="bg-green4 text-green11 hover:bg-green5 focus:shadow-green7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none focus:shadow-[0_0_0_2px] focus:outline-none"
              >
                Submit
              </button>
            </Dialog_.Close>
          </div>

          <Dialog_.Close asChild>
            <button
              className="text-violet11 hover:bg-violet4 focus:shadow-violet7 absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>
          </Dialog_.Close>
        </Dialog_.Content>
      </Dialog_.Portal>
    </Dialog_.Root>
  );
};
