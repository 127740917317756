import React from "react";
import { useParams } from "react-router-dom";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import { ScrollArea } from "../../components";

export const Overview = () => {
  const { id } = useParams();

  const { data: summaries = {}, isLoading: sumariesLoading } = useQuery(
    [queries.summaries],
    () => getRequest(`summaries/conversation?conversation_id=${id}`),
    {
      enabled: !!id,
    }
  );
  const { data: actionItems = [] } = useQuery(
    [queries.actionItems],
    () => getRequest(`actions/conversation?conversation_id=${id}`),
    {
      enabled: !!id,
    }
  );

  if (sumariesLoading) {
    return (
      <div className="w-full h-full grid place-content-center text-white">
        Loading ...
      </div>
    );
  }

  console.log({ summaries });

  return (
    <div className="w-full pl-10 pb-10 h-full space-y-4 text-white mt-8">
      <ScrollArea className="w-full h-[calc(100vh-515px)]">
        <div className="space-y-6">
          <div
            className="conversation-container"
            dangerouslySetInnerHTML={{ __html: summaries.body }}
          />

          <div className="space-y-2">
            <p className="font-bold">Action Items</p>
            <ul className=" text-perl pl-5 list-[square]">
              {actionItems.map((obj: any) => {
                return <li className="list-inside   text-perl ">{obj.body}</li>;
              })}
            </ul>

            <p className="font-bold text-white">Key Points</p>
            <ul className="text-perl pl-5 list-[square]">
              {summaries?.data?.key_points
                ?.split("-")
                .map(
                  (point: string, index: number) =>
                    point && <li key={index}>{point}</li>
                )}{" "}
            </ul>

            <p className="font-bold text-white">Concerns</p>
            <p className="text-perl pl-5">{summaries?.data?.concerns || " -- "}</p>

            <p className="font-bold text-white">Overall Sentiment</p>
            <p className="text-perl pl-5">
              {summaries?.data?.overall_sentiment}
            </p>
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
