import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { CustomAudioPlayer, DefaultAudioPlayer } from "../../components";
import { formatTime } from "../../utils";
import { format } from "date-fns";

export const Header = () => {
  const { state } = useLocation();
  const [duration, setDuration] = useState<number>(0);


  return (
    <div className="w-full h-full p-8 text-white space-y-4 border border-header-border bg-primary rounded-2xl shadow-lg">
      <div className="w-full flex  items-center space-x-12">
        <div className="space-y-1 text-nowrap">
          <p className="font-bold">Assistant</p>
          <div>
            <img src="" />
            <p className=" text-disabled">{state.name}</p>
          </div>
        </div>

        <div className="w-[0.25px] h-12 bg-header-border"></div>

        <div className="space-y-1 text-nowrap">
          <p className="font-bold">Duration</p>
          <p className=" text-disabled" >{formatTime(duration)}</p>
        </div>

        <div className="w-[0.25px] h-12 bg-header-border" />

        <div className="space-y-1 text-nowrap">
          <p className="font-bold">Date</p>
          <p className=" text-disabled">{format(state.created_at, "MMM dd, yyyy hh:mm a")}</p>
        </div>

        <div className="w-[0.25px] h-12 bg-header-border" />

        <div className="space-y-1 text-nowrap">
          <p className="font-bold">Phone Number</p>
          <p className=" text-disabled">{state.phone_number}</p>
        </div>

        <div className="w-[0.25px] h-12 bg-header-border"></div>

        <div className="space-y-1 text-nowrap">
          <p className="font-bold">Call Status</p>
          <p className=" text-disabled">{state.status ?? '-'}</p>
        </div>

      </div>
      <div className="bg-header-border w-full h-[1px]" />
      <div className="">
        {/* <DefaultAudioPlayer duration={duration} setDuration={setDuration} url={state.recording_url} /> */}
        <CustomAudioPlayer duration={duration} setDuration={setDuration} url={state.recording_url} />
      </div>
    </div>
  );
};
