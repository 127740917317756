import React from "react";
import { CustomAudioPlayer, ScrollArea } from "../../components";
import { format } from "date-fns";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";

export const Transcriptions = () => {
  const { id } = useParams();
  const { state } = useLocation();


  

  const { data: transcriptions = [] } = useQuery(
    [queries.transcriptions],
    () => getRequest(`transcripts/conversation?conversation_id=${id}`),
    {
      enabled: !!id,
    }
  );
  return (
    <div className="w-full h-full space-y-6 mt-6">
      <div>
        <ScrollArea className="w-full h-[calc(100vh-465px)]">
          <div className="space-y-4 py-3">
            {transcriptions.map((obj: any) => {
              return (
                <div key={obj.id} className="rounded pl-10 p-2">
                  <div className="w-full flex justify-between items-center">
                    <p
                      className={`${
                        obj.role === "user" ? "text-green " : "text-white"
                      } capitalize`}
                    >
                      {obj.role}
                    </p>
                    <p className="text-perl pr-10">{`[${format(
                      new Date(obj.created_at),
                      "hh:mm:ss a"
                    )}]`}</p>
                  </div>
                  <p className="text-perl text-sm pl-5 w-[80%]">
                    {obj.content}
                  </p>
                </div>
              );
            })}
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};
