import { useState } from "react";
import { useTable } from "react-table";
import { ConfirmDialog } from "../dialog";

export function CustomizeDataGrid({
  activeTab,
  columns,
  actionList,
  data,
  onUpdateSubmit,
  onRowClick,
}: any) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<string>();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const renderDeleteUI = (id: string) => {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="bg-red-500 text-white px-1.5  text-sm rounded-md"
      >
        Delete
        <ConfirmDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(!isOpen)}
          onConfirm={() => onUpdateSubmit(id)}
        />
      </button>
    );
  };

  return (
    <div className="shadow overflow-hidden border-b border-t border-header-border">
      <table
        {...getTableProps()}
        className="min-w-full divide-y divide-header-border"
      >
        <thead className="bg-transparent">
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column: any, index2: number) => (
                <th
                  key={index2}
                  scope="col"
                  className="group py-3 text-left font-bold text-white capitalize tracking-wider"
                  {...column.getHeaderProps()}
                >
                  <div className="flex items-center justify-between">
                    {column.render("Header")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="relative bg-transparent divide-y divide-header-border"
        >
          {rows.map((row: any, i: any) => {
            // new
            prepareRow(row);
            return (
              <tr
                onMouseEnter={() => setShowDelete(row.original.id)}
                onMouseLeave={() => setShowDelete("")}
                className="cursor-pointer hover:bg-[#424244]"
                onClick={() => onRowClick(row.original)}
                {...row.getRowProps()}
                key={i}
              >
                {row.cells.map((cell: any, index: number) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-3 whitespace-nowrap"
                      role="cell"
                      key={index}
                    >
                      {actionList.includes(activeTab) &&
                      showDelete === row.original.id &&
                      cell.column.Header === "Status"
                        ? renderDeleteUI(row.original.id)
                        : cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
