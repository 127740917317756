import React from 'react'
import { Link } from 'react-router-dom'

export const Settings = () => {
  return (
    <div className="w-full h-full p-4 text-white">
   <p>Settings</p>
  </div>
  
  )
}