import React from "react";
import * as Select_ from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";

export const CustomSelect = ({
  data,
  onValueChange,
  defaultValue,
  children,
  ...props
}: any) => (
  <div className={props.className}>
    {children}
    <Select_.Root defaultValue={defaultValue} onValueChange={onValueChange}>
      <Select_.Trigger
        className={`w-full inline-flex mt-4 py-2 px-4 items-center justify-between bg-transparent border focus:outline-none ${
          props.theme === "black"
            ? "text-black  border-black rounded-lg data-[placeholder]:text-black"
            : "text-white  border-white rounded-lg data-[placeholder]:text-white"
        }`}
        aria-label={props.placeholder}
      >
        <Select_.Value placeholder={props.placeholder} />
        <Select_.Icon>
          <ChevronDownIcon />
        </Select_.Icon>
      </Select_.Trigger>
      <Select_.Portal>
        <Select_.Content className="w-full overflow-hidden bg-primary rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <Select_.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white  cursor-default">
            <ChevronUpIcon />
          </Select_.ScrollUpButton>
          <Select_.Viewport className="p-[5px] text-white">
            {data?.map((object: any) => renderContent(object))}
          </Select_.Viewport>
          <Select_.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-white cursor-default">
            <ChevronDownIcon />
          </Select_.ScrollDownButton>
        </Select_.Content>
      </Select_.Portal>
    </Select_.Root>
  </div>
);

const renderContent = (obj: any) => {
  return (
    <Select_.Group key={obj.id}>
      <SelectItem value={obj.id}>{obj.name}</SelectItem>
    </Select_.Group>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }: any, forwardedRef: any) => {
    return (
      <Select_.Item
        className={classnames(
          "text-xs leading-none rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select_.ItemText>{children}</Select_.ItemText>
        <Select_.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select_.ItemIndicator>
      </Select_.Item>
    );
  }
);
