import React from "react";
import {
  HamburgerMenuIcon,
  PlusIcon,
  MagnifyingGlassIcon,
  ChevronDownIcon,
  MixerHorizontalIcon,
  AvatarIcon,
  GearIcon,
  ArrowRightIcon,
  CheckIcon,
  ClipboardIcon,
  Share1Icon,
} from "@radix-ui/react-icons";

import { DropdownMenu } from "../../components";
import { useStoreActions, useStoreState } from "../../store/easy-peasy/hooks";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";

interface HomeStats {
  total_calls: number;
  inbound_calls: number;
  outbound_calls: number;
  total_call_duration: number;
  inbound_calls_duration: number;
  outbound_calls_duration: number;
  total_appointments: number;
  booked_appointments: number;
  cancelled_appointments: number;
}

export const MainHeader = ({ onToggle }: any) => {
  const navigate = useNavigate();
  let user = useStoreState((store) => store.user);
  let logout = useStoreActions((store) => store.logout);

  const {
    data: homestats,
    isLoading,
    isError,
  } = useQuery<HomeStats>([queries.homestats], () =>
    getRequest(`/statistics/all`)
  );

  const profile = [
    {
      id: "1",
      name: "top",
      path: "",
      component: (
        <div className="flex flex-col justify-center items-center p-4 text-center">
          {/* <div className="bg-yellow-500 rounded-full p-5">
            <p className="text-white">WA</p>
          </div> */}
          <div className="mt-3">
            {/* <p className="text-white"> My workspace </p> */}
            <p className="text-white">{user.email}</p>
          </div>
        </div>
      ),
    },
    // {
    //   id: "2",
    //   name: "Admin Console",
    //   path: "",
    //   icon: <MixerHorizontalIcon />,
    // },
    {
      id: "3",
      name: "separater",
      path: "",
    },
    {
      id: "4",
      name: "Profile",
      path: "/profile",
      icon: <AvatarIcon />,
    },
    {
      id: "5",
      name: "Settings",
      path: "/settings",
      icon: <GearIcon />,
    },
    {
      id: "6",
      name: "separater",
      path: "",
    },
    {
      id: "7",
      name: "Logout",
      path: "",
      icon: <ArrowRightIcon />,
      logout: () => {
        logout();
        navigate("/auth");
      },
    },
  ];

  const create = [
    {
      id: "1",
      name: "Project",
      path: "/create-project",
      icon: <ClipboardIcon />,
    },
    {
      id: "3",
      name: "separater",
      path: "",
    },

    {
      id: "4",
      name: "Invite",
      path: "",
      icon: <Share1Icon />,
    },
  ];

  return (
    <div className="w-full flex items-center justify-between px-8 h-16 bg-primary border-b border-header-border shadow-lg">
      <div className="flex items-center space-x-4 text-white">
        <button
          onClick={onToggle}
          className="active:rounded-full  p-2 bg-transparent active:bg-black active:opacity-80"
        >
          <HamburgerMenuIcon className="w-6 h-6" />
        </button>

        <h2 className=" text-logo font-bold text-3xl font-custom">Tring</h2>
      </div>

      {/* <div className="w-[35%] relative flex items-center space-x-2 bg-search rounded-full px-2 py-1 border border-header-border text-header-text">
        <MagnifyingGlassIcon className="tw-absolute inset-0 w-6 h-6 text-header-text" />
        <input
          className="w-full bg-transparent focus:outline-none"
          placeholder="Search"
        />
      </div> */}

      <div className="flex items-center space-x-4 text-white">
        <div className="flex justify-between items-center space-x-6 text-white">
          <p>{homestats?.total_calls} of 1000 FREE calls</p>
          {/* <Link to="/subscription" className="bg-blue-500 rounded px-4 py-1">
            Subscribe Now
          </Link> */}
          <DropdownMenu
            innerStyle="min-w-[280px] flex flex-col  rounded-md bg-secondary shadow-xl z-100 m-2 py-2"
            arrowStyle="fill-secondary"
            isArrowShow={false}
            data={profile}
          >
            <div className="flex items-center space-x-1">
              <div className="bg-perl rounded-full w-10 h-10 grid place-content-center">
                <p className="text-primary uppercase">
                  {user.name.substring(0, 2)}
                </p>
              </div>
              <ChevronDownIcon className="w-5 h-5 text-header-text" />
            </div>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};
