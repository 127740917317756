import React from "react";
import { Routes } from "./routes";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./components";
import ReactQueryLoading, { queryClient } from "./react-query";
import { QueryClientProvider } from "react-query";
import { useStoreState } from "./store/easy-peasy/hooks";


function App() {
  let isAuthenticated = useStoreState((store) => store.isAuthenticated);

  return (
    <ErrorBoundary
      fallback={<ErrorFallback />}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    >
      <QueryClientProvider client={queryClient}>
        {/* <ReactQueryLoading /> */}
        <Routes isAuthorized={isAuthenticated} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
