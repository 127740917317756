import React from "react";
import { useAudioRecorder } from "../../hooks";

interface Model {
  id: string;
  name: string;
  age: string;
  pitch: string;
  flag: any;
  picture: any;
}

export const Wrapper = ({ data }: { data: Model }) => {
  const { audioStream } = useAudioRecorder();
  return (
    <div className="bg-black rounded-2xl">
      <div className="p-2 space-y-1">
        <div className="relative w-full bg-primary rounded-2xl grid place-content-center ">
          {audioStream && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.91 11.672a.375.375 0 0 1 0 .656l-5.603 3.113a.375.375 0 0 1-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112Z"
              />
            </svg>
          )}
          {data.picture}
        </div>
        <p className="text-white text-xs text-center">{data.name}</p>
      </div>

      <div className="h-[0.25px] w-full bg-white" />
      <div className="flex justify-between items-center p-1.5">
        <p className="text-white text-xs">{data.age}</p>
        <p className="text-white text-xs">{data.pitch} pitch</p>
        <div>{data.flag}</div>
      </div>
    </div>
  );
};
