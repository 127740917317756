import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import Play from "../../assets/images/play.svg";
import Pause from "../../assets/images/pause.svg";
import { formatTime } from "../../utils";

export const DefaultAudioPlayer = ({ url, setDuration, duration }: any) => {
  const playerRef = useRef<any>(null);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [currentTime, setCurrentTime] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const handleSeekChange = (e: any) => {
    console.log("e----->", e.target.value);
    const newTime = parseFloat(e.target.value);
    setCurrentTime(newTime);
    if (playerRef.current) {
      playerRef.current.seekTo(newTime, "seconds");
    }
  };

  useEffect(() => {
    if (playerRef.current && !seeking) {
      playerRef.current.seekTo(currentTime, "seconds");
    }
  }, [currentTime, seeking]);

  return (
    <div className="w-full">
      <ReactPlayer
        ref={playerRef}
        url={url}
        step={1}
        playing={playing}
        volume={volume}
        onDuration={setDuration}
        onProgress={({ playedSeconds }) => {
          if (!seeking) {
            setCurrentTime(playedSeconds);
          }
        }}
        height="0px"
        width="0px"
        style={{ display: "none" }}
      />

      <div className="w-full inline-flex items-center space-x-4">
        <button className="text-black" onClick={() => setPlaying(!playing)}>
          {playing ? (
            <img src={Pause} width={30} height={30} alt="pause" />
          ) : (
            <img src={Play} width={30} height={30} alt="play" />
          )}
        </button>

        <div className="flex  items-center space-y-2 w-full ">
          <div className="text-center w-30 text-white text-nowrap">
            <span>{formatTime(currentTime)}</span> /{" "}
            <span>{formatTime(duration)}</span>
          </div>

          <input
            type="range"
            min="0"
            max={duration}
            step="0"
            value={currentTime}
            onChange={handleSeekChange}
            onMouseDown={() => setSeeking(true)}
            onMouseUp={() => setSeeking(false)}
            onTouchStart={() => setSeeking(true)}
            onTouchEnd={() => setSeeking(false)}
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
};
