import React, { useState } from "react";
import {
  DefaultSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";

const providers = [
  {
    label: "Twilio",
    value: "twilio",
  },
];

export const CreatePhone = () => {
  const navigate = useNavigate();

  const { data: agents = [] } = useQuery([queries.agents], () =>
    getRequest(`/agents/org`)
  );

  const [formData, setFormData] = useState<any>({
    phone: "",
    provider: {
      label: "",
      value: "",
    },
    auth_token: "",
    account_sid: "",
    agent: {
      label: "",
      value: "",
    },
    status: true,
  });

  const [errors, setErrors] = useState({
    phone: "",
    provider: "",
    auth_token: "",
    account_sid: "",
    agent: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createPhone } = useMutation(
    postRequest,
    optimisticOptions(queries.phones)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { phone, provider, auth_token, account_sid, agent, status } =
      formData;
    createPhone({
      endPoint: "/phone-numbers/",
      payload: {
        phone_number: phone,
        provider: provider.value,
        status: status === true ? 1 : 0,
        provider_key: {
          auth_token: auth_token,
          account_sid: account_sid,
        },
        inbound_agent_id: agent.value,
      },
    });
    navigate(-1);
  };

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-white">New</span>{" "}
          </div>

          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)] ">
              <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="New Phone Number"
                  value={formData.phone}
                  onChange={(e) => onChangeHandler(e.target.value, "phone")}
                  required
                  theme="white"
                  error={errors.phone}
                >
                  <label htmlFor="Welcome">Phone Number</label>
                </TextInput>

                <DefaultSelect
                  theme="white"
                  data={providers}
                  value={formData.provider}
                  defaultValue={formData.provider}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "provider")
                  }
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="Welcome">Providers</label>
                </DefaultSelect>

                <TextInput
                  type="text"
                  placeholder="Enter Provider Token"
                  value={formData.auth_token}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "auth_token")
                  }
                  required
                  theme="white"
                  error={errors.auth_token}
                >
                  <label htmlFor="Welcome">Provider token</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Enter SID from Provider"
                  value={formData.account_sid}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "account_sid")
                  }
                  required
                  theme="white"
                  error={errors.account_sid}
                >
                  <label htmlFor="Welcome">SID from Provider</label>
                </TextInput>

                <DefaultSelect
                  theme="white"
                  className="bg-transparent rounded-lg  border border-header-border p-2"
                  data={agents}
                  value={formData.agent}
                  defaultValue={formData.agent}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "agent")
                  }
                  placeholder="Select"
                  keyField="id"
                  valueField="name"
                >
                  <label htmlFor="Welcome">Assign to Assistant</label>
                </DefaultSelect>
              </div>
            </ScrollArea>
            <div className="flex justify-end">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
