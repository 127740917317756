import { toLower, map, capitalize } from 'lodash';

export function uppercaseSpacedToCapitalized(input:string) {
  // Convert the input string to lowercase and split it into words
  const words = toLower(input).split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = map(words, capitalize);

  // Join the words to form the capitalized string
  return capitalizedWords.join(' ');
}

