import React from "react";
import Logo from "../../assets/images/header/logo.png";
import Profile from "../../assets/images/header/profile.png";

export function AnonymousHeader() {
  return (
    <header className="w-full h-16 bg-secondary flex justify-between items-center px-4">
      <div className=" flex space-x-2 items-center">
        <img
          className="object-cover"
          src="https://static.wixstatic.com/media/eb285e_f713b72b5bde4d4ba7a80fb661f9f0cb~mv2.png/v1/fill/w_186,h_186,al_c,lg_1,q_85,enc_auto/eb285e_f713b72b5bde4d4ba7a80fb661f9f0cb~mv2.png"
          width={50}
          height={50}
          alt="logo"
        />
        
      </div>

      {/* <div className="flex justify-between items-center space-x-6 text-white">
        <p>25 days left in trial</p>
        <button className="bg-blue-500 rounded px-4 py-1">Add billing info</button>
        <img
          className="object-cover"
          src={Profile}
          width={24}
          height={24}
          alt="profile"
        />
      </div> */}
    </header>
  );
}
