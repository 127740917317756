import React, { useEffect, useState } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { MultiSelect } from "../../components/select/MultiSelect";
import { useStoreState } from "../../store/easy-peasy/hooks";

const providers = [
  {
    label: "Twilio",
    value: "twilio",
  },
];

export const UpdateKnowledge = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useStoreState((store) => store.user);

  const { data: knowledge_base = {} } = useQuery(
    [queries.knowledges, id],
    () => getRequest(`/knowledge_bases/?knowledge_base_id=${id}`),
    {
      enabled: !!id,
    }
  );
  const [formData, setFormData] = useState<any>({
    title: "",
    purpose: "",
    body: "",
    status:true
  });

  const [errors, setErrors] = useState({
    title: "",
    purpose: "",
    body: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateKnowledge } = useMutation(
    putRequest,
    optimisticOptions(queries.knowledges)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { title, body, purpose, status } = formData;
    updateKnowledge({
      endPoint: `/knowledge_bases/?knowledge_base_id=${id}`,
      payload: {
        title: title,
        body: body,
        purpose: purpose,
        status: status === true ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (knowledge_base) {
      const { title, body, purpose, status } = knowledge_base;
      setFormData({
        ...formData,
        title,
        body,
        purpose,
        status: status === 1 ? true : false,
      });
    }
  }, [knowledge_base]);

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">Update</span>
          </div>

          <div className="flex space-x-6 text-white">
            {user.access_level > 0 && (<NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />)}

              {user.access_level > 0 &&(<button className="inline-flex space-x-2 items-center" >
                  <TrashIcon />
                </button>)}
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-340px)] ">
              <div className="space-y-2 grid grid-cols-2 gap-x-5 gap-y-4 px-4">
                <TextInput
                  type="text"
                  placeholder="eg. San Jose - Service List"
                  value={formData.title}
                  onChange={(e) => onChangeHandler(e.target.value, "title")}
                  required
                  theme="white"
                  error={errors.title}
                >
                  <label htmlFor="title">Name</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="eg. Services available in San Jose location"
                  value={formData.purpose}
                  onChange={(e) => onChangeHandler(e.target.value, "purpose")}
                  required
                  theme="white"
                  error={errors.purpose}
                >
                  <label htmlFor="Purpose">Prompt</label>
                </TextInput>

                <div className="col-span-2">
                  <TextArea
                    className="focus:outline-none min-h-[40vh] bg-transparent rounded-lg  border border-header-border p-2"
                    placeholder="Enter the content in detail for virtual employee can understand "
                    value={formData.body}
                    onChange={(e) => onChangeHandler(e.target.value, "body")}
                    required
                    theme="white"
                    error={errors.body}
                  >
                    <label htmlFor="Phone" className="pt-4">
                      Content
                    </label>
                  </TextArea>
                </div>
              </div>
            </ScrollArea>
            <div className="flex justify-end">
            {user.access_level > 0 ? (<button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Save
              </button>) : null}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
