import { useNavigate } from "react-router-dom";
import { NormalDataGrid, ScrollArea } from "../../components";
import { useMemo, useState } from "react";
import {
  putRequest,
  optimisticOptions,
  queries,
  postRequest,
} from "../../react-query";
import { toast } from "react-toastify";

export const List = ({
  activeTab,
  data,
  children,
  columns,
  onRowClick,
  loadMoreRef
}: {
  activeTab: number;
  data: any;
  children: any;
  columns: any;
  onRowClick: any;
  loadMoreRef?:any
}) => {
  const renderTopUI = () => {
    return <>{children}</>;
  };

  return (
    <div className="w-full h-full p-8 space-y-8 border border-header-border bg-primary rounded-2xl shadow-lg">
      {renderTopUI()}

      <ScrollArea className="w-full h-[calc(100vh-310px)]">
        <NormalDataGrid
          activeTab={activeTab}
          actionList={[1, 0, -1]}
          columns={columns}
          data={data}
          onRowClick={onRowClick}
        />
            <div ref={loadMoreRef} className="h-4"></div> 
      </ScrollArea>
    </div>
  );
};
