import React, { useEffect } from "react";

import ReactPlayer from "react-player";
import PlayIcon from "../../assets/images/play.png";
import {
  SpeakerLoudIcon,
  SpeakerOffIcon,
  ValueNoneIcon,
} from "@radix-ui/react-icons";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import weaknessIcon from "../../assets/images/weakness.png";

export const S3AudioPlayer = ({
  url,
  playbackRate,
  isPlaying,
  handleEnded,
  togglePlaying,
  is_modified,
}: any) => {
  const { data: signedUrl, isLoading } = useQuery(
    [queries.masterVideoUrl, url],
    () => getRequest(`media/generate-signed-url?path=${url}`),
    {
      enabled: !!url,
    }
  );

  return (
    <>
      <button
        disabled={isLoading}
        onClick={(event) => {
          togglePlaying();
          event.stopPropagation();
        }}
        className={`h-8 px-4 rounded-lg border ${
          isPlaying ? "border-red-500" : "border-header-border"
        }  text-white`}
      >
        {isPlaying ? (
          <SpeakerOffIcon className="text-red-500" />
        ) : (
          <SpeakerLoudIcon />
        )}
      </button>

      {signedUrl && (
        <ReactPlayer
          url={signedUrl.media_url}
          playIcon={playbackRate}
          playing={isPlaying}
          controls
          hidden
          onEnded={handleEnded}
        />
      )}
    </>
  );
};
