import React, { useState, useEffect } from "react";
import { NormalSwitch, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { postRequest, optimisticOptions, queries } from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";
import axios from "axios";

interface FormData {
  [key: string]: string | boolean;
  status: boolean;
}

interface Integration {
  system_name: string;
  description: string;
  integration_status: string;
  integration_type: string;
  required_data: { [key: string]: any };
  data_collected: { [key: string]: string };
}

interface Placeholders {
  [key: string]: string;
}

interface CreateIntegrationProps {
  integrations: Integration[];
}

export const CreateIntegration: React.FC<CreateIntegrationProps> = ({
  integrations,
}) => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>(
    integrations.find((integration) => integration.system_name) ||
      integrations[0]
  );

  // Fields to be displayed
  const displayedFields = [
    "client_id",
    "client_secret",
    "api_key",
    "api_base_url",
    "auth_url",
    "refresh_token",
    "integration_notes",
  ];

  // Initial form data setup
  const initialFormData: FormData = {
    status: true,
    ...Object.fromEntries(displayedFields.map((key) => [key, ""])),
  };

  const [formData, setFormData] = useState<FormData>(initialFormData);

  // Fetch placeholders from API
  const {
    isLoading,
    isError,
    data: placeholders,
  } = useQuery<Placeholders | null>(
    ["placeholders", selectedIntegration.system_name],
    async () => {
      try {
        const response = await axios.get(
          `/integrations/master-integration/${selectedIntegration.system_name}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching placeholders:", error);
        return null; // Handle error appropriately
      }
    }
  );

  // Update form data with placeholders when fetched (using optional chaining)
  useEffect(() => {
    if (placeholders) {
      const updatedFormData = {
        ...formData,
        ...placeholders,
      };
      setFormData(updatedFormData);
    }
  }, [placeholders]);

  // Update form data when integration changes (using reduce)
  useEffect(() => {
    const initialData = displayedFields.reduce((acc, field) => {
      acc[field] = selectedIntegration.data_collected[field] || "";
      return acc;
    }, {} as FormData);
    setFormData((prev) => ({ ...prev, ...initialData }));
  }, [selectedIntegration]);

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createIntegration } = useMutation(
    postRequest,
    optimisticOptions(queries.integrations)
  );

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    createIntegration({
      endPoint: "/integrations/",
      payload: {
        ...formData,
        status: formData.status ? 1 : 0,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-white">
              New Integration {selectedIntegration.system_name}
            </span>
            <p>{selectedIntegration.description}</p>
          </div>
          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status as boolean}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>
        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border-header-border bg-primary p-4">
            <ScrollArea className="h-[calc(100vh-300px)] ">
              <div className="space-y-2 grid-cols-1 gap-x-5 gap-y-4 px-4">
                {displayedFields.map((key) => (
                  <div key={key}>
                    <label htmlFor={key} className="block mt-2">
                      {key.replace(/_/g, " ").toLowerCase()}
                    </label>
                    <TextInput
                      type="text"
                      placeholder={
                        placeholders?.[key] ||
                        selectedIntegration.data_collected[key]
                      }
                      value={formData[key]?.toString() || ""}
                      onChange={(e) => onChangeHandler(e.target.value, key)}
                      required
                      theme="white"
                    />
                  </div>
                ))}
              </div>
            </ScrollArea>
            <div className="flex justify-end">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Create
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
