import React, { useEffect, useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Draft",
    value: 2,
  },
  {
    id: "3",
    name: "Deleted",
    value: -1,
  },
];

export const AgentsList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({
    tab: 1,
  });

  const { data: agents = [] } = useQuery(
    [queries.agents, input.tab, user.org_id],
    () =>
      getRequest(
        `/agents/org?org_id=${user.org_id}&status=${
          input.tab === 2 ? 0 : input.tab
        }`
      ),
    {
      enabled: !!input.tab || !!user.org_id,
    }
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Name</span>,
        accessor: "name",
      },

      // { Header: "Description", accessor: "description" },
      {
        Header: () => <span className="text-white">Voice</span>,
        accessor: "voice",
      },
      {
        Header: () => <span className="text-white">Role</span>,
        accessor: "role",
        Cell: ({ value }: any) => (
          <span className="truncate-text" title={value}>
            {value}
          </span>
        ),
      },
      {
        Header: () => <span className="text-white">Created</span>,
        accessor: "created_at",
        //@ts-ignore
        Cell: ({ value }) => {
          return format(new Date(value), "MMM dd, yyyy hh:mm a");
        },
      },
      // {
      //   Header: "Call",
      //   Cell: ({ value }: any) => {
      //     return <span className="px-2">Test</span>;
      //   },
      // },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/agents/update`, {
      state: {
        data: data,
      },
    });
  };

  return (
    <div className="w-full h-full p-10 text-perl">
      <List
        columns={columns}
        data={agents}
        activeTab={input["tab"]}
        onRowClick={handleRowClick}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black">Virtual Employees</h1>

          <div className="flex   text-disabled  justify-between items-center">
            <ToggleGroup
              innerClass="w-full text-white flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white  data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input["tab"]}
              onValueChange={(value: number) =>
                setInput({ ...input, ["tab"]: value })
              }
            />

            <button
              onClick={() => navigate("/virtualstaff")}
              className="bg-green text-white px-5 py-2 w-[175px] h-[50px] rounded-full"
            >
              Hiring Center
            </button>
          </div>
        </div>
      </List>
    </div>
  );
};
