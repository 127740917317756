import React from "react";
import * as Switch from "@radix-ui/react-switch";

export const NormalSwitch = ({ ...props }) => {
  return (
    <div className="flex items-center">
      <label
        className="text-white text-[15px] leading-none pr-[15px]"
        htmlFor="airplane-mode"
      >
        {props.title}
      </label>
      <Switch.Root
        {...props}
        className="w-[42px] h-[25px] bg-transparent rounded-full relative shadow-[0_2px_10px] shadow-blackA4 focus:shadow-[0_0_0_2px] focus:shadow-green data-[state=checked]:bg-green outline-none cursor-default"
        id="airplane-mode"
        //@ts-ignore
        style={{ "-webkit-tap-highlight-color": "rgba(0, 0, 0, 0)" }}
      >
        <Switch.Thumb className="block w-[21px] h-[21px] bg-white rounded-full shadow-[0_2px_2px] shadow-gray-300 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
      </Switch.Root>
    </div>
  );
};
