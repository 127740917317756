import React, { useState } from "react";
import Bg from "../assets/images/bg.png";
import { SigninForm, SignupForm } from "../components";
import { ForgotPasswordForm } from "../components/auth/ResetPasswordForm";

export function AuthWrapper() {
  const [authPage, setAuthPage] = useState("SigninForm");

  const renderTitle = () => {
    switch (authPage) {
      case "ResetPasswordForm":
        return <h1 className="text-2xl">Forgot Password</h1>;
      case "SigninForm":
        return <h1 className="text-2xl">Sign in now</h1>;
      case "SignupForm":
        return <h1 className="text-2xl">Sign up now</h1>;
      default:
        return <h1 className="text-2xl">Sign in now</h1>;
    }
  };

  const renderForm = () => {
    switch (authPage) {
      case "ResetPasswordForm":
        return <ForgotPasswordForm setAuthPage={setAuthPage} />;
      case "SigninForm":
        return <SigninForm setAuthPage={setAuthPage} />;
      case "SignupForm":
        return <SignupForm setAuthPage={setAuthPage} />;
      default:
        return <SigninForm setAuthPage={setAuthPage} />;
    }
  };

  const renderBottom = () => {
    switch (authPage) {
      case "SigninForm":
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Forgot Password?{" "}
            <a
              onClick={() => setAuthPage("ResetPasswordForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Reset Password here
            </a>
          </p>
        );
      case "SignupForm":
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Already have an account?{" "}
            <a
              href="javascript:void(0);"
              onClick={() => setAuthPage("SigninForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Signin here
            </a>
          </p>
        );
      case "ResetPasswordForm":
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Already have an account?{" "}
            <a
              href="javascript:void(0);"
              onClick={() => setAuthPage("SigninForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Signin here
            </a>
          </p>
        );
      default:
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Not a member?{" "}
            <a
              href="javascript:void(0);"
              onClick={() => setAuthPage("SignupForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Signup here
            </a>
          </p>
        );
    }
  };

  return (
    <div className="w-full h-full flex  items-center bg-primary">
      <div className="relative w-1/2 h-[400px] flex flex-col justify-center items-center text-white">
        <div className="px-[120px] space-y-9">
          <h1 className="text-5xl font-black text-logo font-custom">Tring</h1>
          <p className="text-justify">
            Discover how Tring can automate your inbound and outbound calls and
            schedule service appointments, sales enquiries 24/7. Experience
            unparalleled efficiency and value for your dealership with Tring’s
            advanced capabilities.
          </p>
        </div>

        <div className="w-[400px] h-[400px]">
          <img className="w-full h-full" src={Bg} alt="bg" />
        </div>
      </div>

      <div className="h-[560px] w-[500px] bg-white rounded-lg p-8 space-y-5">
        {renderTitle()}
        {renderForm()}
        {renderBottom()}
      </div>
    </div>
  );
}
