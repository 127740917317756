import React, { useCallback, useEffect, useMemo, useState } from "react";
import { List, TextInput } from "../../components";
import { useInfiniteQuery, useMutation } from "react-query";
import {
  deleteRequest,
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { useDebounce } from "../../hooks";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

type Car = {
  id: string;
  org_id: string;
  make: string;
  model: string;
  variant: string;
  year: number;
  mileage: number;
  price: number;
  color: string;
  fuel_type: string;
  transmission: string;
  stock: string;
  condition: string;
  body_type: string;
  VIN: string;
  location: string;
  search_keywords: string;
  summary: string;
};

type ApiResponse = Array<{
  _index: string;
  _id: string;
  _score: number;
  _source: Car;
}>;

export const CarList = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCars, setSelectedCars] = useState<string[]>([]);
  const [isAllCarsSelected, setIsAllCarsSelected] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);
  const [isDeleteSelectedDialogOpen, setIsDeleteSelectedDialogOpen] =
    useState(false);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { mutate: deleteSelectedCars } = useMutation(postRequest);

  const handleDeleteSelectedCars = () => {
    deleteSelectedCars({
      endPoint: `/Car/cars/bulk-delete-by-ids`,
      payload: {
        ids: selectedCars,
      },
    });
    window.location.reload();
  };

  const { mutate: deleteAllCars } = useMutation(
    deleteRequest,
    optimisticOptions(queries.cars)
  );
  const handleDeleteAllCars = () => {
    deleteAllCars({
      endPoint: `/Car/cars/bulk-delete/${user.org_id}`,
      payload: {},
    });
    window.location.reload();
  };

  const fetchCars = async ({
    pageParam = currentPage,
  }: {
    pageParam: number;
  }): Promise<ApiResponse> => {
    const response = getRequest(
      `/Car/get_matching_cars?search_keywords=${searchQuery}&org_id=${user.org_id}&page=${pageParam}&per_page=20&detailed=true`
    );
    return response;
  };

  const { data, fetchNextPage, hasNextPage, refetch, isLoading } =
    useInfiniteQuery({
      queryKey: queries.cars,
      queryFn: ({ pageParam }) => fetchCars({ pageParam }),
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length === 20 ? allPages.length + 1 : undefined;
      },
    });

  const handleSelectCar = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedCars((prev) => [...prev, id]);
    } else {
      setSelectedCars((prev) => prev.filter((carId) => carId !== id));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div className="px-3">
            <input
              type="checkbox"
              onChange={(e) => setIsAllCarsSelected(e.target.checked)}
              checked={isAllCarsSelected}
            />
          </div>
        ),
        accessor: "_id",
        Cell: ({ value }: { value: string }) => {
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              className="px-3"
            >
              <input
                type="checkbox"
                onChange={(e) => {
                  handleSelectCar(e.target.checked, value);
                }}
                checked={selectedCars.includes(value)}
              />
            </div>
          );
        },
      },
      {
        Header: () => <span className="text-white">Brand</span>,
        accessor: "_source.make",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Model</span>,
        accessor: "_source.model",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Variant</span>,
        accessor: "_source.variant",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Color</span>,
        accessor: "_source.color",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Type</span>,
        accessor: "_source.body_type",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Fuel Type</span>,
        accessor: "_source.fuel_type",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Transmission</span>,
        accessor: "_source.transmission",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Location</span>,
        accessor: "_source.location",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white w-full">Condition</span>,
        accessor: "_source.condition",
        Cell: ({ value }: { value: any }) => (
          <div className="text-center">{value}</div>
        ),
      },
      {
        Header: () => <span className="text-white w-full">Mileage</span>,
        accessor: "_source.mileage",
        Cell: ({ value }: { value: any }) => (
          <div className="text-center">{value}</div>
        ),
      },
      {
        Header: () => <span className="text-white">Year</span>,
        accessor: "_source.year",
        Cell: ({ value }: { value: any }) => <span>{value}</span>,
      },
      {
        Header: () => <span className="text-white">Price</span>,
        accessor: "_source.price",
        Cell: ({ value }: { value: any }) => (
          <span className="text-green car-item">$ {value}</span>
        ),
      },
    ],
    [selectedCars, isAllCarsSelected]
  );

  const handleRowClick = (data: any) => {
    navigate(`/cars/update`, {
      state: {
        data: data,
      },
    });
  };

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  useEffect(() => {
    setSelectedCars([]);
    setIsAllCarsSelected(false);
    setCurrentPage(1);
    refetch();
  }, [debouncedSearchQuery, refetch]);

  useEffect(() => {
    if (isAllCarsSelected) {
      const allCarIds =
        data?.pages.flatMap((page) => page.map((car) => car._id)) || [];

      setSelectedCars(allCarIds);
    } else {
      setSelectedCars([]);
    }
  }, [isAllCarsSelected, data?.pages]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          observer.unobserve(lastCarElement as Element);
          loadMore();
        }
      },
      { threshold: 0.2 }
    );
    const parentElement = document.querySelector("[role=rowgroup]");
    const lastCarElement = parentElement?.lastChild;

    if (!lastCarElement) return;

    observer.observe(lastCarElement as Element);

    return () => {
      observer.unobserve(lastCarElement as Element);
      observer.disconnect();
    };
  }, [data, loadMore]);

  return (
    <div className="w-full h-full p-10 text-perl">
      {isLoading && <div className="text-center text-white">Loading...</div>}

      {data && (
        <List
          columns={columns}
          data={data?.pages.flatMap((page) => page)}
          activeTab={1}
          onRowClick={handleRowClick}
        >
          <div className="space-y-8 flex flex-col">
            <h1 className="text-xl font-black">Car Inventory</h1>
            <div className="w-full flex justify-between items-center">
              <div className="w-1/4">
                <TextInput
                  type="text"
                  id="query"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="car name"
                >
                  {" "}
                  <label className="text-base" htmlFor="query">
                    Search
                  </label>
                </TextInput>
              </div>
              <div className="flex   text-disabled  justify-center items-center gap-4">
                <AlertDialog.Root
                  open={isDeleteAllDialogOpen}
                  onOpenChange={setIsDeleteAllDialogOpen}
                >
                  <AlertDialog.Trigger asChild>
                    <button className="bg-red-600 hover:bg-red-700 text-white  w-[150px] h-[45px] rounded-full transition-colors">
                      Delete All
                    </button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Portal>
                    <AlertDialog.Overlay className="bg-black/50 fixed inset-0" />
                    <AlertDialog.Content className="w-full h-fit bg-white rounded-lg shadow-lg max-w-md p-6 fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <AlertDialog.Title className="text-lg font-medium">
                        Confirm Deletion
                      </AlertDialog.Title>
                      <AlertDialog.Description className="mt-2 text-sm">
                        Are you sure you want to delete all cars? This action
                        cannot be undone.
                      </AlertDialog.Description>
                      <div className="mt-4 flex justify-end space-x-4">
                        <AlertDialog.Cancel asChild>
                          <button
                            className="px-4 py-2 bg-gray-300 rounded-md"
                            onClick={() => setIsDeleteAllDialogOpen(false)}
                          >
                            Cancel
                          </button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action asChild>
                          <button
                            className="px-4 py-2 bg-red-500 text-white rounded-md"
                            onClick={handleDeleteAllCars}
                          >
                            Delete
                          </button>
                        </AlertDialog.Action>
                      </div>
                    </AlertDialog.Content>
                  </AlertDialog.Portal>
                </AlertDialog.Root>
                <AlertDialog.Root
                  open={isDeleteSelectedDialogOpen}
                  onOpenChange={setIsDeleteSelectedDialogOpen}
                >
                  <AlertDialog.Trigger asChild>
                    <button
                      className="bg-red-600 hover:bg-red-700 text-white disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed w-[150px] h-[45px] rounded-full transition-colors"
                      disabled={selectedCars.length === 0}
                    >
                      Delete Selected
                    </button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Portal>
                    <AlertDialog.Overlay className="bg-black/50 fixed inset-0" />
                    <AlertDialog.Content className="w-full h-fit bg-white rounded-lg shadow-lg max-w-md p-6 fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <AlertDialog.Title className="text-lg font-medium">
                        Confirm Deletion
                      </AlertDialog.Title>
                      <AlertDialog.Description className="mt-2 text-sm">
                        Are you sure you want to delete all selected cars? This
                        action cannot be undone.
                      </AlertDialog.Description>
                      <div className="mt-4 flex justify-end space-x-4">
                        <AlertDialog.Cancel asChild>
                          <button
                            className="px-4 py-2 bg-gray-300 rounded-md"
                            onClick={() => setIsDeleteSelectedDialogOpen(false)}
                          >
                            Cancel
                          </button>
                        </AlertDialog.Cancel>
                        <AlertDialog.Action asChild>
                          <button
                            className="px-4 py-2 bg-red-500 text-white rounded-md"
                            onClick={handleDeleteSelectedCars}
                          >
                            Delete
                          </button>
                        </AlertDialog.Action>
                      </div>
                    </AlertDialog.Content>
                  </AlertDialog.Portal>
                </AlertDialog.Root>

                <button
                  onClick={() => navigate("/cars/create")}
                  className="bg-green text-white w-[150px] h-[45px] rounded-full"
                >
                  Add Car
                </button>
              </div>
            </div>
          </div>
        </List>
      )}
    </div>
  );
};
