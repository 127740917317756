import React from 'react';
import { DropdownMenu } from '../../components';
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";

// Define the type for subscription data
interface BillingPlan {
  id: string;
  name: string;
  price: string;
  period: string;
  features: string[];
  payment_url: string;
}

export const Subscription = () => {
  // Fetching the statistics data using react-query
  const { data: subscriptions, isLoading, isError } = useQuery<BillingPlan[]>(
    [queries.subscriptions],
    () => getRequest(`/billing/plans`)
  );

  if (isLoading) return <div className="w-full h-full p-4 text-center text-white">Loading...</div>;
  if (isError) return <div className="w-full h-full p-4 text-center text-white">Error fetching subscriptions</div>;

  return (
    <div className="w-full h-full p-4">
      <div className="text-center w-full px-4">
        <h1 className="text-white text-2xl mb-4 pt-10 pb-10">Pick your plan</h1>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          {subscriptions?.map((plan) => (
            <div key={plan.id} className="w-full rounded-lg p-4">
              <div className={` pr-5 h-[500px]  md:p-8 rounded-lg border text-perl border-header-border bg-primary text-center flex flex-col justify-between ${plan.id === 'pro' ? 'transform scale-115' : ''}`}>
                <div>
                  <p className="text-xl font-bold">{plan.name}</p>
                  <p className="text-4xl my-2">{plan.price}</p>
                  <p className="text-lg pb-5">{plan.period}</p>
                  <div className="text-left">
                    {plan.features.map((feature, index) => (
                      <p key={index} className="flex text-sm pt-4">
                        <svg
                          className="h-4 w-4 ml-2 text-green-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                        <span className="pl-2">{feature}</span>
                      </p>
                    ))}
                  </div>
                </div>
                <a href={plan.payment_url} className={`bg-green text-white px-3 py-1 rounded-full ${plan.id === 'free' ? 'cursor-not-allowed' : ''}`}>
                  {plan.id === 'free' ? 'Default Plan' : 'Get Plan'}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};