import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  theme?: string;
}

export const TextInput: React.FC<TextInputProps> = ({
  error,
  theme,
  children,
  ...props
}) => {
  const isDisabled = props.disabled;

  return (
    <div
      className={`w-full flex flex-col justify-center space-y-1 ${
        theme === "black" ? "text-black" : "text-white"
      }`}
    >
      {children}

      <input
        className={`focus:outline-none focus:ring-2 focus:ring-white border rounded-lg h-10 px-3 ${
          theme === "black"
            ? "bg-transparent border-header-border"
            : "text-white bg-transparent border-header-border"
        } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
        {...props}
      />
      {error && <div className="text-xs text-red-600">{error}</div>}
    </div>
  );
};
