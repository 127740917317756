import React from "react";
import { useNavigate } from "react-router-dom";
import { ScrollArea } from "../../components";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";

// Define the interface for the Integration object
interface Integration {
  system_name: string;
  description: string;
  integration_status: string;
  integration_type: string;
  required_data: Record<string, string | null>;
  data_collected: Record<string, string>;
  scopes?: string[];
}

interface APIResponse {
  integrations: Integration[];
}

export const MasterIntegration = () => {
  const navigate = useNavigate();

  // Fetching data using useQuery
  const { data, isLoading, isError, error } = useQuery<APIResponse>(
    [queries.integrations],
    () => getRequest("integrations/master-integration"),
    {
      retry: 1, // Retry once if the request fails
      refetchOnWindowFocus: false, // Disable refetch on window focus
    }
  );

  // Default to empty array if data is undefined
  const integrationList = data?.integrations || [];

  // Handle loading and error states
  if (isLoading) {
    return <div className="text-center text-white">Loading...</div>;
  }

  if (isError) {
    return (
      <div className="text-center text-white">
        Error: {(error as Error)?.message}
      </div>
    );
  }

  return (
    <div className="w-full h-full p-4 text-white">
      <ScrollArea className="h-[calc(100vh-85px)]">
        <div className="text-center w-full px-4">
          <h1 className="text-white text-2xl pt-20 mb-20">
            Which integration would you like to
            <span className="text-4xl p-2 font-black text-logo font-custom letter-spacing:">
              {" "}
              configure{" "}
            </span>
            today?
          </h1>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 pt-18">
            {integrationList.map((integration: Integration) => (
              <div key={integration.system_name}>
                <div className="bg-primary border h-[430px] border-header-border p-6 rounded-lg text-white hover:bg-[#333333] cursor-pointer flex flex-col relative">
                  <div className="flex items-center mb-4 justify-left">
                    {/* Icon */}
                    <div className="h-12 w-12 flex items-center justify-center bg-header-border rounded mr-2">
                      <svg
                        className="h-6 w-6 text-perl"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 4v16m8-8H4"
                        ></path>
                      </svg>
                    </div>

                    {/* Title */}
                    <h2 className="text-xl pl-4 font-bold text-left mb-2">
                      {integration.system_name}
                    </h2>
                  </div>

                  {/* Description */}
                  <p className="text-left text-perl p-25 mb-4">
                    {integration.description}
                  </p>

                  {/* Supported Operations with Checkmarks */}
                  <ul className="text-left pl-3 mb-4">
                    {Array.isArray(integration.required_data?.scopes) &&
                      integration.required_data.scopes.map((scope, index) => (
                        <li key={index} className="flex items-center mb-2">
                          <svg
                            className="h-4 w-4 text-green-500 mr-2"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                          <span className="text-perl">{scope}</span>
                        </li>
                      ))}
                  </ul>

                  <div className="w-full flex absolute bottom-4 right-4 items-center justify-between">
                    {/* <div className="ml-10 text-sm w-24 h-10 rounded-lg bg-primary border border-gray-400 flex items-center justify-center">
                      <span className="text-gray-400">
                        {integration.integration_status}
                      </span>
                    </div> */}
                    <button
                      onClick={() =>
                        navigate(`/integrations/create-integration`)
                      }
                      className="bg-green text-white w-[125px] h-[40px] rounded-full ml-auto"
                    >
                      Configure Now
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ScrollArea>
    </div>
  );
};
