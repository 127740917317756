import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

export const CheckBox: React.FC<TextInputProps> = ({
  error,
  children,
  ...props
}) => {
  return (
    <div className="w-full space-y-1">
      <label className="text-xs flex items-start">
        <input
          className="focus:outline-none accent-black mr-2 w-4 h-4"
          type="checkbox"
          {...props}
        />

        {children}
      </label>

      {error && <div className="text-xs text-red-600">{error}</div>}
    </div>
  );
};
