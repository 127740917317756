import flattenDeep from "lodash/flattenDeep";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

const generateFlattenRoutes = (routes: any) => {
  if (!routes) return [];
  return flattenDeep(
    routes && routes.map(({ routes: subRoutes, ...rest }: any) => [
      rest,
      generateFlattenRoutes(subRoutes),
    ])
  );
};

export const renderRoutes = (mainRoutes: any) => {
  const Routes = ({ isAuthorized }: any) => {
    const layouts = mainRoutes.map(({ layout: Layout, routes, isPublic }: any, index: number) => (
      <Route key={index} element={<Layout />}>
        <Route element={<ProtectedRoute isPublic={isPublic} isAuthorized={isAuthorized} />}>
          {generateFlattenRoutes(routes).map(({ element: Element, path, name }: any) => (
            Element && path && <Route key={name} element={Element} path={path} />
          ))}
        </Route>
      </Route>
    ));
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };
  return Routes;
};


