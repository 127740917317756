import React from "react";
import * as ToggleGroup_ from "@radix-ui/react-toggle-group";

export const ToggleGroup = ({ data, onValueChange, value, defaultValue, ...props }: any) => (
  
  <ToggleGroup_.Root
    className={`${props.innerClass}`}
    type="single"
    defaultValue={defaultValue}
    value={value}
    aria-label="Text alignment"
    onValueChange={onValueChange}
  >
    {data.map((ele: any) => {
      return (
        <ToggleGroup_.Item
          key={ele.id}
          className={`flex  text-disabled items-center focus:outline-none ${props.outerClass}`}
          value={ele.value}
          aria-label={ele.name}
        >
          <div>{ele.icon}</div>
          <p className="font-bold">{ele.name}</p>
        </ToggleGroup_.Item>
      );
    })}
  </ToggleGroup_.Root>
);
