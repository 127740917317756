import React from 'react'
import { ScrollArea } from '../../components'


const faqData = [
  {
    question: "What is your return policy?",
    answer: "You can return any item within 30 days of purchase for a full refund."
  },

  {
    question: "Do you offer international shipping?",
    answer: "Yes, we offer international shipping to select countries."
  },
  {
    question: "How can I track my order?",
    answer: "Once your order has shipped, you will receive a tracking number via email."
  },
  {
    question: "Can't find your answer?",
    answer: "You can reach us via email at support@gettring.com"
  }
];

export const Help = () => {
  return (
    
    <div className="w-full h-full p-4">
    <div className="text-center w-full px-4">
      <h1 className="text-white text-2xl mb-4 pt-10">Questions?</h1>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6"></div>
  
      <div className="w-full flex justify-center text-left space-y-4 text-white">
        <div className="w-[60%] rounded-lg border border-header-border bg-primary p-4">
          <ScrollArea className="h-[calc(100vh-340px)]">
            <div className="space-y-4 px-4">
              {faqData.map((faq, index) => (
                <div key={index} className="rounded-lg  p-3">
                  <h3 className="font-bold">{index + 1}. {faq.question}</h3>
                  <p className="text-sm text-perl pt-3 pl-5">{faq.answer}</p>
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  </div>
  

  )
}

