import React from "react";
import { Route, Outlet, Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  isAuthorized: boolean;
  isPublic: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAuthorized,
  isPublic,
}) => {
  if (isAuthorized && !isPublic) {
    // If user is authorized and route is not public, allow access
    return <Outlet />;
  } else if (isAuthorized && isPublic) {
    // If user is authorized and route is public, redirect to home
    return <Navigate to="/" replace />;
  } else if (!isAuthorized && isPublic) {
    // If user is not authorized but route is public, allow access
    return <Outlet />;
  } else {
    // If user is not authorized and route is not public, redirect to login
    return <Navigate to="/auth" replace />;
  }

};

export default ProtectedRoute;
