import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header } from "./Header";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import {
  CustomAudioPlayer,
  CustomizeDataGrid,
  NormalDataGrid,
  ScrollArea,
  ToggleGroup,
  TranscriptionsDataGrid,
} from "../../components";
import { useLocation, useParams } from "react-router-dom";

import { format } from "date-fns";
import { Transcriptions } from "./Transcriptions";
import { Overview } from "./Overview";
import OverviewIcon from "../../assets/images/report.png";
import TransformIcon from "../../assets/images/transcription.png";
import axios from "axios";

const tabs = [
  {
    id: "1",
    name: "Overview",
    value: "overview",
    icon: (
      <img src={OverviewIcon} width={24} height={24} alt="icon" className="" />
    ),
  },
  {
    id: "2",
    name: "Transcriptions",
    value: "transcriptions",
    icon: (
      <img src={TransformIcon} width={24} height={24} alt="icon" className="" />
    ),
  },
];

export const TranscriptionsList = () => {
  const { state } = useLocation();
  const [input, setInput] = useState({
    tab: "overview",
  });
  const [url, setUrl] = useState<any>();

  const onChangeHandler = (value: string, name: string) => {
    setInput({ ...input, [name]: value });
  };

  const renderUi = useCallback(() => {
    switch (input.tab) {
      case "overview":
        return <Overview />;
      case "transcriptions":
        return <Transcriptions />;
      default:
        return <div />;
    }
  }, [input.tab]);

  return (
    <div className="p-8 space-y-8 ">
      <Header />
      <div className="border border-header-border bg-primary rounded-2xl">
        <ToggleGroup
          innerClass="w-full flex  space-x-12"
          outerClass="space-x-2 pl-10 pt-10 pb-5 text-white opacity-70 rounded data-[state=on]:text-white data-[state=on]:opacity-100"
          data={tabs}
          defaultValue={input["tab"]}
          onValueChange={(value: string) => {
            if (value) {
              setInput({ ...input, ["tab"]: value });
            }
          }}
        />
        <div className="flex bg-header-border  w-full h-[1px] items-center" />
        {renderUi()}
      </div>
    </div>
  );
};
