import { useState, useEffect } from 'react';

type AudioStream = MediaStream | null;
type AudioTrack = MediaStreamTrack | null;

export const useAudioRecorder = () => {
  const [audioStream, setAudioStream] = useState<AudioStream>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [audioUrl, setAudioUrl] = useState<string>('');

  useEffect(() => {
    let canceled = false;

    const startRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        if (!canceled) {
          setAudioStream(stream);
        }
      } catch (error) {
        console.error('Error accessing microphone:', error);
      }
    };

    if (isRecording) {
      startRecording();
    } else if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
      setAudioStream(null);
    }

    return () => {
      canceled = true;
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
        setAudioStream(null);
      }
    };
  }, [isRecording, audioStream]);

  const toggleRecording = () => {
    if (!audioStream) {
      requestPermission();
    } else {
      setIsRecording(prevState => !prevState);
    }
  };

  const requestPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const playRecordedAudio = () => {
    if (audioUrl) {
      const audio = new Audio(audioUrl);
      audio.play().catch(error => console.error('Error playing audio:', error));
    }
  };

  return { audioStream, isRecording, toggleRecording, audioUrl, setAudioUrl, playRecordedAudio };
};


