import { AxiosError, AxiosProgressEvent } from "axios";
import axiosInstance from "./axios.config";
import { store } from "../store/easy-peasy";

type RequestConfig = {
  endPoint: string;
  payload?: {};
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  headers?: {};
};

const makeRequest = async (
  method: "get" | "post" | "put" | "delete",
  { endPoint, payload, onUploadProgress, headers }: RequestConfig
) => {
  try {
    const { data } = await axiosInstance({
      method,
      url: endPoint,
      data: payload,
      headers,
      onUploadProgress: onUploadProgress,
    });

    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response?.status === 401) {
      store.getActions().logout();
    } else {
      console.error(`Error ${method.toUpperCase()} request:`, error);
    }
    
    throw error;
  }
};

const getRequest = async (endPoint: string) => {
  return makeRequest("get", { endPoint });
};

const postRequest = async ({
  endPoint,
  payload,
  onUploadProgress,
}: RequestConfig) => {
  return makeRequest("post", { endPoint, payload, onUploadProgress });
};

const putRequest = async ({ endPoint, payload }: RequestConfig) => {
  return makeRequest("put", { endPoint, payload });
};

const deleteRequest = async ({ endPoint, payload }: RequestConfig) => {
  return makeRequest("delete", { endPoint, payload });
};

export { getRequest, postRequest, putRequest, deleteRequest };
