import React, { forwardRef } from "react";
import * as ScrollArea_ from "@radix-ui/react-scroll-area";

export const ScrollArea = forwardRef<HTMLDivElement, React.PropsWithChildren<any>>(
  ({ children, ...props }, forwardedRef) => (
    <ScrollArea_.Root ref={forwardedRef}>
      <ScrollArea_.Viewport {...props}>
        {children}
      </ScrollArea_.Viewport>
      <ScrollArea_.Scrollbar
        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
        orientation="vertical"
      >
        <ScrollArea_.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
      </ScrollArea_.Scrollbar>
      <ScrollArea_.Scrollbar
        className="flex select-none touch-none p-0.5 bg-blackA3 transition-colors duration-[160ms] ease-out hover:bg-blackA5 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
        orientation="horizontal"
      >
        <ScrollArea_.Thumb className="flex-1 bg-mauve10 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
      </ScrollArea_.Scrollbar>
      <ScrollArea_.Corner className="bg-blackA5" />
    </ScrollArea_.Root>
  )
);


