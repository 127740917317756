import React, { useEffect, useState } from "react";
import {
  NormalSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";
import { useStoreState } from "../../store/easy-peasy/hooks";

import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { MultiSelect } from "../../components/select/MultiSelect";
import { TextArea } from "../../components/input/TextArea";

export const CreateAgent = () => {
  const { template_id, plan_id } = useParams();

  const user = useStoreState((store) => store.user);

  const navigate = useNavigate();

  const {
    data: optionsList = { knowledge_bases: [], calendars: [], locations: [] },
  } = useQuery([queries.optionsList], () =>
    getRequest(`/agents/addon-options`)
  );
  
  const { data: voiceList = [] } = useQuery([queries.voiceList], () =>
    getRequest(`/tts_voices/list`)
  );

  const { data: phoneNumberList = [] } = useQuery([queries.phones], () =>
    getRequest(`/phone-numbers/org?status=1`)
  );

  const { data: agentTemplate = [] } = useQuery([queries.agentTemplate], () =>
    getRequest(`/v2/agent_templates/?agent_template_id=${template_id}`)
  );

  const filteredLocations = optionsList.locations.filter(
    (location: any) => location.org_id === user.org_id
  );

  const filteredCalendars = optionsList.calendars.filter(
    (calendar: any) => calendar.org_id === user.org_id
  );

  const filteredKnowledgeBases = optionsList.knowledge_bases.filter(
    (knowledgeBase: any) => knowledgeBase.org_id === user.org_id
  );

  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [formData, setFormData] = useState<any>({
    name: "",
    role: "",
    welcome_msg: "",
    status: true,
    voice: "",
    language: "en-GB",
    provider: "",
    locations: [],
    calendars: [],
    knowledges: [],
    phoneNumber: "",
    phoneNumberId: "",
  });

  function onChangeHandler(value: string | boolean, key: string) {
    if (key === "voice") {
      const selectedVoice = voiceList.find((v: any) => v.voice_id === value);
      if (selectedVoice) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          voice_id: value,
          provider: selectedVoice.provider,
          voice_name: selectedVoice.name,
          gender: selectedVoice.gender,
          accent: selectedVoice.accent,
          pitch: selectedVoice.pitch,
          language: selectedVoice.language,
        }));
      }
    } else {
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        [key]: value,
      }));
    }
  }

  const { data: agentCreationResponse, mutate: createAgent } = useMutation(
    postRequest,
    optimisticOptions(queries.agents)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (!formData.phoneNumberId) {
      setPhoneNumberError(true);
      return;
    }
    const {
      name,
      role,
      status,
      welcome_msg,
      voice_id,
      voice_name,
      provider,
      gender,
      accent,
      pitch,
      language,
      locations,
      knowledges,
      calendars,
    } = formData;

    const location_ids = locations.map((location: any) => location.value);
    const calendar_ids = calendars.map((calendar: any) => calendar.value);
    const knowledge_base_ids = knowledges.map(
      (knowledge: any) => knowledge.value
    );

    const payload = {
      template_id: template_id,
      plan_id: plan_id,
      name: name,
      role: role,
      description: "",
      welcome_msg: welcome_msg,
      status: status === true ? 1 : 0,
      tts: {
        voice_id: voice_id,
        name: voice_name,
        provider: provider,
        gender: gender,
        accent: accent,
        pitch: pitch,
        language: language,
      },
      knowledge_base_ids: knowledge_base_ids,
      location_ids: location_ids,
      calendar_ids: calendar_ids,
      outbound_number_id: formData.phoneNumberId,
    };

    createAgent({
      endPoint: "/v2/agents/",
      payload: payload,
    });
  };

  useEffect(() => {
    if (agentCreationResponse && agentCreationResponse.id) {
      navigate(`/agents/update`, {
        state: {
          data: agentCreationResponse,
        },
      });
    }
  }, [agentCreationResponse, navigate]);

  return (
    <div className="w-full h-full p-4">
      <form onSubmit={submitHandler} className="h-full py-4 space-y-4">
        <div className="w-[60%] rounded-lg border items-center border-header-border bg-primary  py-4 px-4 flex justify-between space-x-4">
          <div className="space-y-2">
            <div className="w-full flex justify-start space-x-4 ">
              <div className="flex space-x-3 items-center">
                <img
                  className="w-8 h-8 rounded-full"
                  src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />

                <NormalSelect
                  theme="white"
                  className="border-none text-nowrap"
                  data={voiceList.map((ele: any) => ({
                    voice_id: ele.voice_id,
                    voice_name: ele.name,
                  }))}
                  onValueChange={(value: string) => {
                    onChangeHandler(value, "voice");
                  }}
                  defaultValue={formData.voice_id}
                  value={formData.voice_id}
                  placeholder="Voice"
                  keyField="voice_id"
                  valueField="voice_name"
                ></NormalSelect>
              </div>
              <div className="flex space-x-3 items-center">
                <p className="flex space-x-6 items-start text-white text-nowrap">
                  Paired to{" "}
                </p>
                <NormalSelect
                  theme="white"
                  className="border-none text-nowrap"
                  data={phoneNumberList.map((ele: any) => ({
                    phoneNumberId: ele.phone_number,
                    phoneNumber: ele.id,
                  }))}
                  onValueChange={(value: string) => {
                    setPhoneNumberError(false);
                    const selectedItem = phoneNumberList.find(
                      (ele: any) => ele.id === value
                    );
                    if (selectedItem) {
                      onChangeHandler(selectedItem.id, "phoneNumberId");
                      onChangeHandler(selectedItem.phone_number, "phoneNumber");
                    }
                  }}
                  defaultValue={formData.phoneNumberId}
                  value={formData.phoneNumberId}
                  placeholder="Phone Number"
                  keyField="phoneNumber"
                  valueField="phoneNumberId"
                ></NormalSelect>
                <div
                  style={{ color: "red", fontSize: "14px", marginTop: "5px" }}
                >
                  {phoneNumberError ? "Please select a phone number" : ""}
                </div>{" "}
              </div>
            </div>
          </div>

          <div className="flex space-x-6 items-start text-white text-nowrap">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary  p-4">
            <ScrollArea className="h-[calc(100vh-240px)] ">
              <div className="space-y-2 ">
                <TextInput
                  type="text"
                  placeholder="What's your assistant to be called?  (eg. Christof)"
                  value={formData.name}
                  onChange={(e) => onChangeHandler(e.target.value, "name")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg  border border-header-border p-2"
                >
                  <label htmlFor="Welcome" className="pt-4">
                    Assistant Name
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Front office assistant to help booking appointment for car service booking"
                  value={formData.role}
                  onChange={(e) => onChangeHandler(e.target.value, "role")}
                  required
                  theme="white"
                  className=" bg-transparent rounded-lg  border border-header-border p-2"
                >
                  <label htmlFor="Welcome" className="pt-4">
                    Assistant Role
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Welcome to Tring Motors, how can I assist you today?"
                  value={formData.msg}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "welcome_msg")
                  }
                  required
                  theme="white"
                  className=" bg-transparent rounded-lg  border border-header-border p-2"
                >
                  <label htmlFor="Welcome" className="pt-4">
                    Welcome Message
                  </label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[44vh] bg-transparent rounded-lg  border border-header-border p-2"
                  placeholder="Enter instructions for your assistant"
                  value={agentTemplate.prompt_template}
                  theme="white"
                  disabled
                >
                  <label htmlFor="prompt" className="pt-4">
                    Assistant Instructions
                  </label>
                </TextArea>

                <div className="space-y-6 py-4">
                  <MultiSelect
                    theme="black"
                    keyField="id"
                    valueField="name"
                    className="bg-transparent rounded-lg  border border-header-border p-2"
                    data={filteredLocations}
                    onValueChange={(value) =>
                      onChangeHandler(value, "locations")
                    }
                    value={formData.locations}
                    defaultValue={formData.locations}
                    placeholder="Select"
                    required={true}
                  >
                    <label htmlFor="role">Locations</label>
                  </MultiSelect>

                  <MultiSelect
                    theme="black"
                    keyField="id"
                    valueField="title"
                    className="bg-transparent rounded-lg  border border-header-border p-2"
                    data={filteredKnowledgeBases}
                    onValueChange={(value) => {
                      onChangeHandler(value, "knowledges");
                    }}
                    value={formData.knowledges}
                    defaultValue={formData.knowledges}
                    placeholder="Select"
                    required={true}
                  >
                    <label htmlFor="role">Knowledge Bases</label>
                  </MultiSelect>

                  <MultiSelect
                    theme="black"
                    keyField="id"
                    valueField="title"
                    className="bg-transparent rounded-lg  border border-header-border p-2"
                    data={filteredCalendars}
                    onValueChange={(value) =>
                      onChangeHandler(value, "calendars")
                    }
                    value={formData.calendars}
                    defaultValue={formData.calendars}
                    placeholder="Select"
                    required={true}
                  >
                    <label htmlFor="role">Calendars</label>
                  </MultiSelect>
                </div>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-4 py-1.5 bg-green rounded-lg"
                  >
                    Save
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>

          <div className="w-[35%] space-y-8">
            <div className="  p-4 text-white border   border-header-border rounded-lg h-full">
              <p className="font-bold">Test Assistant</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
