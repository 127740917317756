import React, { useState } from "react";
import { NormalSelect, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { TrashIcon } from "@radix-ui/react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";
import * as AlertDialog from "@radix-ui/react-alert-dialog";

export const UpdateCar = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [isTrashOpen, setIsTrashOpen] = useState<boolean>(false);

  const {
    state: { data },
  } = useLocation();

  const car = data._source;

  const { data: optionsList = [] } = useQuery([queries.optionsList], () =>
    getRequest(`/agents/addon-options`)
  );

  const [formData, setFormData] = useState<any>({
    make: car?.make || "",
    model: car?.model || "",
    variant: car?.variant || "",
    year: car?.year || 0,
    mileage: car?.mileage || 0,
    price: car?.price || 0,
    color: car?.color || "",
    fuel_type: car?.fuel_type || "",
    transmission: car?.transmission || "",
    stock: car?.stock || "",
    condition: car?.condition || "",
    body_type: car?.body_type || "",
    VIN: car?.VIN || "",
    location: car?.location || "",
    search_keywords: car?.search_keywords || "",
    summary: car?.summary || "",
  });

  function onChangeHandler(value: string | boolean | number, key: string) {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [key]: value,
    }));
  }

  const { mutate: updateCar } = useMutation(
    putRequest,
    optimisticOptions(queries.cars)
  );

  const { mutate: deleteCar } = useMutation(
    deleteRequest,
    optimisticOptions(queries.agents)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();

    const payload = {
      org_id: user.org_id,
      ...formData,
    };

    updateCar({
      endPoint: `/Car/cars/${data._id}`,
      payload: payload,
    });
    navigate("/cars");
  };

  const handleCarDelete = () => {
    deleteCar({
      endPoint: `/Car/cars/${data._id}`,
      payload: {},
    });
    navigate("/cars");
  };

  return (
    <div className="w-full h-full p-4 overflow-y-hidden">
      <form
        onSubmit={submitHandler}
        className="h-full w-full py-4 space-y-4 flex flex-col items-center"
      >
        <div className="rounded-lg border border-header-border bg-primary py-4 px-4 w-[60%] flex items-center justify-between">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-white text-lg">Update Car</span>{" "}
          </div>
          <div>
            <AlertDialog.Root open={isTrashOpen} onOpenChange={setIsTrashOpen}>
              <AlertDialog.Trigger asChild>
                <button
                  type="button"
                  className="inline-flex space-x-2 items-center text-white hover:text-red-500"
                >
                  <TrashIcon className="w-6 h-6" />
                </button>
              </AlertDialog.Trigger>
              <AlertDialog.Portal>
                <AlertDialog.Overlay className="bg-black/50 fixed inset-0" />
                <AlertDialog.Content className="w-full h-fit bg-white rounded-lg shadow-lg max-w-md p-6 fixed inset-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <AlertDialog.Title className="text-lg font-medium">
                    Confirm Deletion
                  </AlertDialog.Title>
                  <AlertDialog.Description className="mt-2 text-sm">
                    Are you sure you want to delete this car? This action cannot
                    be undone.
                  </AlertDialog.Description>
                  <div className="mt-4 flex justify-end space-x-4">
                    <AlertDialog.Cancel asChild>
                      <button
                        className="px-4 py-2 bg-gray-300 rounded-md"
                        onClick={() => setIsTrashOpen(false)}
                      >
                        Cancel
                      </button>
                    </AlertDialog.Cancel>
                    <AlertDialog.Action asChild>
                      <button
                        className="px-4 py-2 bg-red-500 text-white rounded-md"
                        onClick={handleCarDelete}
                      >
                        Delete
                      </button>
                    </AlertDialog.Action>
                  </div>
                </AlertDialog.Content>
              </AlertDialog.Portal>
            </AlertDialog.Root>
          </div>
        </div>
        <div className="w-full flex justify-center space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary  p-4">
            <ScrollArea className="h-[calc(100vh-240px)] ">
              <div className="space-y-4 ">
                <TextInput
                  id="make"
                  type="text"
                  placeholder="Brand name"
                  value={formData.make}
                  onChange={(e) => onChangeHandler(e.target.value, "make")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg  border border-header-border p-2"
                >
                  <label htmlFor="make" className="pt-4">
                    Brand
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Model"
                  value={formData.model}
                  onChange={(e) => onChangeHandler(e.target.value, "model")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="model">Model</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Variant"
                  value={formData.variant}
                  onChange={(e) => onChangeHandler(e.target.value, "variant")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="variant">Variant</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Year"
                  value={formData.year}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "year")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="year">Year</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Mileage"
                  value={formData.mileage}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "mileage")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="mileage">Mileage</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Price"
                  value={formData.price}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "price")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="price">Price</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Color"
                  value={formData.color}
                  onChange={(e) => onChangeHandler(e.target.value, "color")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="color">Color</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Fuel Type"
                  value={formData.fuel_type}
                  onChange={(e) => onChangeHandler(e.target.value, "fuel_type")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="fuel_type">Fuel Type</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Transmission"
                  value={formData.transmission}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "transmission")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="transmission">Transmission</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Stock"
                  value={formData.stock}
                  onChange={(e) => onChangeHandler(e.target.value, "stock")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="stock">Stock</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Condition"
                  value={formData.condition}
                  onChange={(e) => onChangeHandler(e.target.value, "condition")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="condition">Condition</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Body Type"
                  value={formData.body_type}
                  onChange={(e) => onChangeHandler(e.target.value, "body_type")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="body_type">Body Type</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="VIN"
                  value={formData.VIN}
                  onChange={(e) => onChangeHandler(e.target.value, "VIN")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="VIN">VIN</label>
                </TextInput>

                {optionsList.locations && (
                  <NormalSelect
                    theme="white"
                    className="bg-transparent rounded-lg border border-[#505050] p-2"
                    data={optionsList.locations.map((ele: any) => ({
                      id: ele.name,
                      name: ele.name,
                    }))}
                    onValueChange={(value: string) => {
                      onChangeHandler(value, "location");
                    }}
                    defaultValue={formData.location}
                    value={formData.location}
                    placeholder="Location"
                    keyField="id"
                    valueField="name"
                  >
                    <label htmlFor="VIN">Location</label>
                  </NormalSelect>
                )}

                <TextInput
                  type="text"
                  placeholder="Search Keywords"
                  value={formData.search_keywords}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "search_keywords")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="search_keywords">Search Keywords</label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[30vh] bg-transparent rounded-lg  border border-header-border p-2"
                  value={formData.summary}
                  required
                  onChange={(e) => onChangeHandler(e.target.value, "summary")}
                  theme="white"
                >
                  <label htmlFor="summary">Summary</label>
                </TextArea>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-4 py-1.5 bg-green rounded-lg"
                  >
                    Update
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </form>
    </div>
  );
};
