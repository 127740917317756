import React from 'react';


interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  theme?: string
}

export const TextArea: React.FC<TextAreaProps> = ({ error, theme, children, ...props }) => {
  return (
    <div className={`w-full flex flex-col justify-center space-y-1 ${theme==='black' ? 'text-black' : 'text-white'}`}>
      <div className='flex justify-between items-center'>
        {children}
      </div>
      <textarea
        className={`focus:outline-none focus:ring-2  border rounded-lg h-10 px-1
           ${
            theme==='black' ? 'bg-transparent border-header-border' 
            : 
            'text-white bg-transparent border-header-border'
          }`}
        {...props}
      ></textarea>
      {error && <div className="text-xs text-red-600">{error}</div>}
    </div>
  );
};