import React from "react";
import * as Select_ from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons";

interface NormalSelectProps {
  data: Array<{ id: string; value: string; name: string }>;
  onValueChange: (value: string) => void;
  defaultValue: string;
  value?:string
  placeholder: string;
  className?: string;
  theme?: "black" | "white";
  children?: React.ReactNode;
  keyField: string;
  valueField: string;
}

export const NormalSelect: React.FC<NormalSelectProps> = ({
  data,
  onValueChange,
  defaultValue,
  value,
  children,
  placeholder,
  className,
  theme = "black",
  keyField,
  valueField,
  ...props
}) => (
  <div>
    {children}
    <Select_.Root defaultValue={defaultValue} value={value} onValueChange={onValueChange}>
      <Select_.Trigger
        className={`w-full inline-flex h-10  items-center justify-between bg-transparent ${className}  px-2 space-x-2 focus:outline-none ${
          theme === "black"
            ? "text-black border-black rounded-lg data-[placeholder]:text-black"
            : "text-white border-white rounded-lg data-[placeholder]:text-white"
        }`}
        aria-label={placeholder}
      >
        <Select_.Value className="text-white" placeholder={placeholder} />
        <Select_.Icon>
          <TriangleDownIcon />
        </Select_.Icon>
      </Select_.Trigger>

      <Select_.Portal>
        <Select_.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <Select_.ScrollUpButton className="flex items-center justify-center h-[25px] cursor-default">
            <TriangleUpIcon />
          </Select_.ScrollUpButton>

          <Select_.Viewport className="py-2 px-0.5">
            {data?.map((obj:any) => (
              <SelectItem key={obj[keyField]} value={obj[keyField]}>
                {obj[valueField]}
              </SelectItem>
            ))}
          </Select_.Viewport>

          <Select_.ScrollDownButton className="flex items-center justify-center h-[25px] cursor-default">
            <TriangleDownIcon />
          </Select_.ScrollDownButton>
        </Select_.Content>
      </Select_.Portal>
    </Select_.Root>
  </div>
);

const SelectItem = React.forwardRef<HTMLDivElement, any>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select_.Item
        className={classnames(
          "px-4 select-none data-[disabled]:text-gray-400 data-[disabled]:pointer-events-none data-[highlighted]:outline-none text-black data-[highlighted]:bg-white data-[highlighted]:text-black",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <div className="flex items-center">
          <Select_.ItemText>{children}</Select_.ItemText>
          <Select_.ItemIndicator className="w-[25px] inline-flex items-center justify-center">
            <CheckIcon />
          </Select_.ItemIndicator>
        </div>
      </Select_.Item>
    );
  }
);
