import React, { useMemo, useState } from "react";
import { List, ScrollArea, ToggleGroup } from "../../components";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "../../store/easy-peasy/hooks";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: 1,
  },
  {
    id: "2",
    name: "Inactive",
    value: 0,
  },
  {
    id: "3",
    name: "Archived",
    value: -1,
  },
];

export const IntegrationList: React.FC = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);
  const [input, setInput] = useState({ tab: 1 });

  const { data: integrations = [] } = useQuery(
    [queries.integrations, input.tab, user.org_id],
    () =>
      getRequest(
        `/integrations/list?org_id=${user.org_id}&status=${input.tab}`
      ),
    { enabled: !!input.tab || !!user.org_id }
  );

  const columns = useMemo(
    () => [
      {
        Header: () => <span className="text-white">Integration Name</span>,
        accessor: "integrationName",
      },
      {
        Header: () => <span className="text-white">API Key</span>,
        accessor: "apiKey",
      },
      {
        Header: () => <span className="text-white">Endpoint URL</span>,
        accessor: "endpointUrl",
      },
    ],
    []
  );

  const handleRowClick = (data: any) => {
    navigate(`/integrations/update/${data.id}`, {
      state: {
        name: data.integrationName,
      },
    });
  };

  return (
    <div className="w-full h-full p-10 text-perl">
      <List
        columns={columns}
        data={integrations}
        activeTab={input.tab}
        onRowClick={handleRowClick}
      >
        <div className="space-y-2">
          <h1 className="text-xl font-black">Integrations</h1>
          <div className="flex justify-between items-center">
            <ToggleGroup
              innerClass="w-full text-disabled flex space-x-6"
              outerClass="pb-1 text-sm data-[state=on]:text-white data-[state=on]:text-side-footer-text data-[state=on]:border-b-2 data-[state=on]:border-header-text"
              data={tabs}
              defaultValue={input.tab}
              onValueChange={(value: number) =>
                setInput({ ...input, tab: value })
              }
            />
            {user.access_level > 0 && (
              <button
                onClick={() => navigate("/integrations/master-integration")}
                className="bg-green text-white px-3 py-1 rounded-full"
              >
                Integrations
              </button>
            )}
          </div>
        </div>
      </List>
    </div>
  );
};
