import React, { useState } from "react";
import { NormalSelect, ScrollArea, TextInput } from "../../components";
import { TextArea } from "../../components/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

export const AddCar = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const { data: optionsList = [] } = useQuery([queries.optionsList], () =>
    getRequest(`/agents/addon-options`)
  );

  const [formData, setFormData] = useState<any>({
    make: "",
    model: "",
    variant: "",
    year: 0,
    mileage: 0,
    price: 0,
    color: "",
    fuel_type: "",
    transmission: "",
    stock: "",
    condition: "",
    body_type: "",
    VIN: "",
    location: "",
    search_keywords: "",
    summary: "",
  });

  function onChangeHandler(value: string | boolean | number, key: string) {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [key]: value,
    }));
  }

  const { mutate: createCar } = useMutation(
    postRequest,
    optimisticOptions(queries.cars)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();

    const payload = {
      org_id: user.org_id,
      ...formData,
    };

    createCar({
      endPoint: "/Car/cars",
      payload: payload,
    });
    navigate("/cars");
  };

  return (
    <div className="w-full h-full p-4 overflow-y-hidden">
      <form
        onSubmit={submitHandler}
        className="h-full w-full py-4 space-y-4 flex flex-col items-center"
      >
        <div className="rounded-lg border border-header-border bg-primary py-4 px-4 w-[60%] flex items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-white text-lg">Add Car</span>{" "}
          </div>
        </div>
        <div className="w-full flex justify-center space-x-4 text-offwhite">
          <div className="w-[60%] rounded-lg border border-header-border bg-primary  p-4">
            <ScrollArea className="h-[calc(100vh-240px)] ">
              <div className="space-y-4 ">
                <TextInput
                  id="make"
                  type="text"
                  placeholder="Brand name"
                  value={formData.make}
                  onChange={(e) => onChangeHandler(e.target.value, "make")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg  border border-header-border p-2"
                >
                  <label htmlFor="make" className="pt-4">
                    Brand
                  </label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Model"
                  value={formData.model}
                  onChange={(e) => onChangeHandler(e.target.value, "model")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="model">Model</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Variant"
                  value={formData.variant}
                  onChange={(e) => onChangeHandler(e.target.value, "variant")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="variant">Variant</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Year"
                  value={formData.year}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "year")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="year">Year</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Mileage"
                  value={formData.mileage}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "mileage")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="mileage">Mileage</label>
                </TextInput>

                <TextInput
                  type="number"
                  placeholder="Price"
                  value={formData.price}
                  onChange={(e) =>
                    onChangeHandler(Number(e.target.value), "price")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="price">Price</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Color"
                  value={formData.color}
                  onChange={(e) => onChangeHandler(e.target.value, "color")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="color">Color</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Fuel Type"
                  value={formData.fuel_type}
                  onChange={(e) => onChangeHandler(e.target.value, "fuel_type")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="fuel_type">Fuel Type</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Transmission"
                  value={formData.transmission}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "transmission")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="transmission">Transmission</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Stock"
                  value={formData.stock}
                  onChange={(e) => onChangeHandler(e.target.value, "stock")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="stock">Stock</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Condition"
                  value={formData.condition}
                  onChange={(e) => onChangeHandler(e.target.value, "condition")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="condition">Condition</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Body Type"
                  value={formData.body_type}
                  onChange={(e) => onChangeHandler(e.target.value, "body_type")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="body_type">Body Type</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="VIN"
                  value={formData.VIN}
                  onChange={(e) => onChangeHandler(e.target.value, "VIN")}
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="VIN">VIN</label>
                </TextInput>

                {optionsList.locations && (
                  <NormalSelect
                    theme="white"
                    className="bg-transparent rounded-lg border border-[#505050] p-2"
                    data={optionsList.locations.map((ele: any) => ({
                      id: ele.name,
                      name: ele.name,
                    }))}
                    onValueChange={(value: string) => {
                      onChangeHandler(value, "location");
                    }}
                    defaultValue={formData.location}
                    value={formData.location}
                    placeholder="Location"
                    keyField="id"
                    valueField="name"
                  >
                    <label htmlFor="VIN">Location</label>
                  </NormalSelect>
                )}

                <TextInput
                  type="text"
                  placeholder="Search Keywords"
                  value={formData.search_keywords}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "search_keywords")
                  }
                  required
                  theme="white"
                  className="bg-transparent rounded-lg border border-header-border p-2"
                >
                  <label htmlFor="search_keywords">Search Keywords</label>
                </TextInput>

                <TextArea
                  className="focus:outline-none min-h-[30vh] bg-transparent rounded-lg  border border-header-border p-2"
                  value={formData.summary}
                  required
                  onChange={(e) => onChangeHandler(e.target.value, "summary")}
                  theme="white"
                >
                  <label htmlFor="summary">Summary</label>
                </TextArea>

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="px-4 py-1.5 bg-green rounded-lg"
                  >
                    Save
                  </button>
                </div>
              </div>
            </ScrollArea>
          </div>
        </div>
      </form>
    </div>
  );
};
