import React, { useEffect, useState } from "react";
import {
  CustomSelect,
  DefaultSelect,
  NormalSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";

import { TrashIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

const providers = [
  {
    label: "twilio",
    value: "twilio",
  },
];

export const UpdatePhone = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useStoreState((store) => store.user);
  
  const { data: agents = [] } = useQuery([queries.agents], () =>
    getRequest(`/agents/org`)
  );

  const { data: phone = {} } = useQuery(
    [queries.phones, id],
    () => getRequest(`/phone-numbers/?phone_number_id=${id}`),
    {
      enabled: !!id,
    }
  );

  const [formData, setFormData] = useState<any>({
    phone: "",
    provider: {
      label: "",
      value: "",
    },
    auth_token: "",
    account_sid: "",
    status: true,
    agent: {
      label: "",
      value: "",
    },
  });

  const [errors, setErrors] = useState({
    phone: "",
    provider: "",
    auth_token: "",
    account_sid: "",
    agent: "",
    status: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updatePhone } = useMutation(
    putRequest,
    optimisticOptions(queries.phones)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const { phone, provider, auth_token, account_sid, agent, status } =
      formData;
    updatePhone({
      endPoint: `/phone-numbers/?phone_number_id=${id}`,
      payload: {
        phone_number: phone,
        provider: provider.value,
        status: status === true ? 1 : 0,
        provider_key: {
          auth_token: auth_token,
          account_sid: account_sid,
        },
        inbound_agent_id: agent.value,
      },
    });
    navigate(-1);
  };

  useEffect(() => {
    if (phone && phone.provider_key) {
      const { phone_number, provider, provider_key, inbound_agent, status } =
        phone;
      setFormData({
        ...formData,
        phone: phone_number,
        status: status === 1 ? true : false,
        provider: {
          label: provider,
          value: provider,
        },
        auth_token: provider_key["auth_token"],
        account_sid: provider_key["account_sid"],
        agent: {
          label: inbound_agent?.name,
          value: inbound_agent?.id,
        },
      });
    }
  }, [phone]);

  return (
    <>
      {formData && (
        <div className="w-full h-full p-4">
          <form
            onSubmit={submitHandler}
            className="w-[65%] mx-auto h-full py-4 space-y-8"
          >
            <div className="rounded-lg border border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
              <div className="space-y-2 lg:min-w-[500px]">
                <span className="text-white">Update</span>
              </div>

              <div className="flex space-x-6 text-white">
                {user.access_level > 0 && (<NormalSwitch
                  title="Status"
                  checked={formData.status}
                  onCheckedChange={(value: boolean) =>
                    onChangeHandler(value, "status")
                  }
                />)}

                {user.access_level > 0 &&(<button className="inline-flex space-x-2 items-center" >
                  <TrashIcon />
                </button>)}
              </div>
            </div>

            <div className="w-full flex justify-between space-x-4 text-white">
              <div className="w-full rounded-lg border border-header-border bg-primary p-4">
                <ScrollArea className="h-[calc(100vh-340px)] ">
                  <div className="space-y-2 grid grid-cols-1 gap-x-5 gap-y-4 px-4">
                    <div className="mt-2">
                      <TextInput
                        type="text"
                        placeholder="Enter Phone Number"
                        value={formData.phone}
                        onChange={(e) =>
                          onChangeHandler(e.target.value, "phone")
                        }
                        required
                        theme="white"
                        error={errors.phone}
                        readOnly={user.access_level <= 0}
                      >
                        <label htmlFor="phone">Phone Number</label>
                      </TextInput>
                    </div>

                    <DefaultSelect
                      theme="white"
                      data={providers}
                      value={formData.provider}
                      defaultValue={formData.provider}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "provider")
                      }
                      placeholder="Select"
                      keyField="value"
                      valueField="label"
                      disabled={user.access_level <= 0}
                    >
                      <label htmlFor="Welcome">Providers</label>
                    </DefaultSelect>

                    <TextInput
                      type="text"
                      placeholder="Enter Provider Token"
                      value={formData.auth_token}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "auth_token")
                      }
                      required
                      theme="white"
                      error={errors.auth_token}
                      readOnly={user.access_level <= 0}
                    >
                      <label htmlFor="Welcome">Provider token</label>
                    </TextInput>

                    <TextInput
                      type="text"
                      placeholder="Enter Provider SID"
                      value={formData.account_sid}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "account_sid")
                      }
                      required
                      theme="white"
                      error={errors.account_sid}
                      readOnly={user.access_level <= 0}
                    >
                      <label htmlFor="Welcome">Provider SID</label>
                    </TextInput>

                    <DefaultSelect
                      theme="white"
                      data={agents}
                      value={formData.agent}
                      defaultValue={formData.agent}
                      onValueChange={(value: any) =>
                        onChangeHandler(value, "agent")
                      }
                      placeholder="Select"
                      keyField="id"
                      valueField="name"
                      disabled={user.access_level <= 0}
                    >
                      <label htmlFor="Welcome">Assigned to Assistant</label>
                    </DefaultSelect>
                  </div>
                </ScrollArea>
                <div className="flex justify-end">
                  
                  {user.access_level > 0 ? (
                    <button
                        type="submit"
                        className="px-4 py-1.5 bg-green rounded-lg"
                      >
                    Save
                  </button>
                  ) : null}

                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
