import React, { useEffect, useState } from "react";
import {
  CustomSelect,
  DefaultSelect,
  NormalSelect,
  NormalSwitch,
  ScrollArea,
  TextInput,
} from "../../components";
import { TextArea } from "../../components/input/TextArea";
import {
  ArrowLeftIcon,
  GearIcon,
  CardStackIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { useStoreState } from "../../store/easy-peasy/hooks";

const providers = [
  {
    label: "Twilio",
    value: "Twilio",
  },
];

const durations = [
  {
    label: "30 minutes",
    value: 30,
  },
  {
    label: "60 minutes",
    value: 60,
  },
  {
    label: "45 minutes",
    value: 45,
  },
];

const max_slots = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },

  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const CreateCalendar = () => {
  const navigate = useNavigate();
  const user = useStoreState((store) => store.user);

  const { data: locations = [] } = useQuery([queries.locations], () =>
    getRequest(`/locations/list`)
  );

  const { data: time_zones = [] } = useQuery([queries.time_zones], () =>
    getRequest(`/calendars/time-zones`)
  );

  const [formData, setFormData] = useState<any>({
    title: "",
    purpose: "",
    duration: {
      label: "",
      value: "",
    },
    start_hours: "",
    closing_hours: "",
    max_slot: {
      label: "",
      value: "",
    },
    provider: "",
    api_key: "",
    event_id: "",
    location: {
      label: "",
      value: "",
    },
    time_zone: {
      label: "",
      value: "",
    },
    status: true,
  });

  const [errors, setErrors] = useState({
    title: "",
    purpose: "",
    duration: "",
    start_hours: "",
    closing_hours: "",
    max_slot: "",
    provider: "",
    location: "",
    time_zone: "",
    api_key: "",
    event_id: "",
    status: "",
  });

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createCalendar } = useMutation(
    postRequest,
    optimisticOptions(queries.calendars)
  );

  const submitHandler = (e: any) => {
    e.preventDefault();
    const {
      title,
      purpose,
      provider,
      location,
      start_hours,
      closing_hours,
      max_slot,
      api_key,
      event_id,
      status,
      time_zone,
      duration,
    } = formData;
    createCalendar({
      endPoint: `/calendars/`,
      payload: {
        title: title,
        purpose: purpose,
        location_id: location.value,
        start_hours,
        closing_hours,
        duration: duration.value,
        max_slots: max_slot.value,
        api_key: {
          eventId: event_id,
          key: api_key,
        },
        provider: provider.value,
        status: status === true ? 1 : 0,
        time_zone: time_zone.value,
        org_id: user.org_id,
      },
    });
    navigate(-1);
  };

  return (
    <div className="w-full h-full p-4">
      <form
        onSubmit={submitHandler}
        className="w-[65%] mx-auto h-full py-4 space-y-8"
      >
        <div className="rounded-lg border-header-border bg-primary py-2 px-4 w-full flex justify-between items-center">
          <div className="space-y-2 lg:min-w-[500px]">
            <span className="text-perl text-base font-perl">New</span>
          </div>

          <div className="flex space-x-6 text-white">
            <NormalSwitch
              title="Status"
              checked={formData.status}
              onCheckedChange={(value: boolean) =>
                onChangeHandler(value, "status")
              }
            />
          </div>
        </div>

        <div className="w-full flex justify-between space-x-4 text-white">
          <div className="w-full rounded-lg border  border-header-border bg-primary   p-4">
            <ScrollArea className="h-[calc(100vh-310px)] ">
              <div className="space-y-2 grid grid-cols-2 gap-x-5 gap-y-4 px-4">
                <div className="mt-2">
                  <TextInput
                    type="text"
                    placeholder="San Jose service booking"
                    value={formData.title}
                    onChange={(e) => onChangeHandler(e.target.value, "title")}
                    required
                    theme="white"
                    error={errors.title}
                  >
                    <label htmlFor="title">Name</label>
                  </TextInput>
                </div>

                <TextInput
                  type="text"
                  placeholder="Book all the services for San Jose using this calendar"
                  value={formData.purpose}
                  onChange={(e) => onChangeHandler(e.target.value, "purpose")}
                  required
                  theme="white"
                  error={errors.purpose}
                >
                  <label htmlFor="Purpose">Prompt</label>
                </TextInput>

                <TextInput
                  type="time"
                  placeholder="Enter"
                  value={formData.start_hours}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "start_hours")
                  }
                  required
                  theme="white"
                  error={errors.start_hours}
                >
                  <label htmlFor="start_hours">Work Hours(Start)</label>
                </TextInput>

                <TextInput
                  type="time"
                  placeholder="Enter"
                  value={formData.closing_hours}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "closing_hours")
                  }
                  required
                  theme="white"
                  error={errors.closing_hours}
                >
                  <label htmlFor="closing_hours">Work Hours(End)</label>
                </TextInput>

                <DefaultSelect
                  theme="white"
                  data={providers}
                  value={formData.provider}
                  defaultValue={formData.provider}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "provider")
                  }
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="Welcome">Provider</label>
                </DefaultSelect>

                <DefaultSelect
                  theme="white"
                  data={durations}
                  value={formData.duration}
                  defaultValue={formData.duration}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "duration")
                  }
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="duration">Duration</label>
                </DefaultSelect>

                <TextInput
                  type="text"
                  placeholder="API Key from the provider"
                  value={formData.api_key}
                  onChange={(e) => onChangeHandler(e.target.value, "api_key")}
                  required
                  theme="white"
                  error={errors.api_key}
                >
                  <label htmlFor="api_key">API Key</label>
                </TextInput>

                <TextInput
                  type="text"
                  placeholder="Event ID"
                  value={formData.event_id}
                  onChange={(e) => onChangeHandler(e.target.value, "event_id")}
                  required
                  theme="white"
                  error={errors.event_id}
                >
                  <label htmlFor="event_id">Event ID</label>
                </TextInput>

                <DefaultSelect
                  theme="white"
                  data={locations}
                  defaultValue={formData.location}
                  value={formData.location}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "location")
                  }
                  placeholder="Select"
                  keyField="id"
                  valueField="name"
                >
                  <label htmlFor="duration">Location</label>
                </DefaultSelect>

                <DefaultSelect
                  theme="white"
                  data={time_zones.map((timezone: any) => ({
                    label: timezone,
                    value: timezone,
                  }))}
                  defaultValue={formData.time_zone}
                  value={formData.time_zone}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "time_zone")
                  }
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="duration">Time Zone</label>
                </DefaultSelect>

                <DefaultSelect
                  theme="white"
                  data={max_slots}
                  defaultValue={formData.max_slot}
                  value={formData.max_slot}
                  onValueChange={(value: any) =>
                    onChangeHandler(value, "max_slot")
                  }
                  placeholder="Select"
                  keyField="value"
                  valueField="label"
                >
                  <label htmlFor="max_slot">
                    Simultaneous Appointments Allowed
                  </label>
                </DefaultSelect>
              </div>
            </ScrollArea>
            <div className="flex justify-end">
              <button type="submit" className="px-4 py-1.5 bg-green rounded-lg">
                Create
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
