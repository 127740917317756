import { SidebarFooter } from "./sidebar-footer";
import { renderSidebars } from "../../routes";
import { Link, NavLink } from "react-router-dom";
import { PlusIcon, ClipboardIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "../../components";
import { useStoreState } from "../../store/easy-peasy/hooks";

export const Sidebar = ({ isOpen, onClose }: any) => {
  const user = useStoreState((store) => store.user);
  return (
    <div
      className={`relative bg-primary min-h-full transform transition-opacity duration-1000 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        className={`min-w-60 h-full flex flex-col space-y-3 py-3 item-center text-white ${
          isOpen ? "visible" : "hidden"
        }`}
      >
        <div className="flex flex-col space-y-3 w-[88%] mx-auto">
          {renderSidebars([
            "home",
            "calls",
            "agents",
            "phones",
            "locations",
            "calendars",
            "car-inventory",
            "knowledge-bases",
            ...(user.access_level > 0 ? ["team-members"] : []),
            "integrations",
          ]).map((data: any) => (
            <NavLink
              key={data.title}
              to={data.path}
              style={({ isActive }) => {
                return isActive
                  ? { backgroundColor: "#505050" }
                  : { backgroundColor: "transparent" };
              }}
              className="inline-flex space-x-2 py-0.5 items-center text-[#FFFFFE] rounded-md px-2"
            >
              {data.icon}
              <p>{data.title}</p>
            </NavLink>
          ))}
        </div>

        <div className="border-t border-header-border" />

        <SidebarFooter />
      </div>
    </div>
  );
};
